import React, {useState} from "react";
import {Button, Col, Container, Modal, Spinner} from "react-bootstrap";
import {Images} from "../../../helpers/Images";
import {Env} from "../../../helpers/Env";
import InputHF from "../../../components/Generic/InputHF";
import * as yup from "yup";
import * as md5 from "md5";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import useApi from "../../../hooks/Api";
import useAuth from "../../../hooks/Auth";
import {useHistory} from "react-router-dom";
import {toast} from "react-hot-toast";


const LoginModal = ({openModal, closeModal, changeStepModal}) => {

    const [loading, setLoading] = useState(false)
    let api = useApi()
    const {login} = useAuth();
    const history = useHistory();

    const loginSchema = yup.object().shape({
        email_or_cpf: yup.string()
            .required('Informe seu e-mail ou CPF para realizar o login')
            .test('email_or_cpf', 'Insira um e-mail ou CPF válido', (value) => {
                return validateEmail(value) || value && value.length === 11
            }),
        password: yup.string().required('Informe sua senha para realizar o login')
    });

    const validateEmail = (email) => {
        return yup.string().email().isValidSync(email)
    };

    const {register, handleSubmit, formState: { errors },} = useForm({
        resolver: yupResolver(loginSchema),
    });

    const handleLogin = (data) => {
        setLoading(true)
        let loginType = data.email_or_cpf?.indexOf('@') > -1 ? 'email' : 'cpf'
        let sendApi = {
            [loginType]: data.email_or_cpf,
            password: md5(data.password),
            application_name: Env.application_name
        }

        api.post(`access/login`, sendApi, (res) => {
            toast.success('Seja bem-vindo(a)!')
            afterLogin(res)
        },(e) => {
            toast.error(e.message || 'Não foi possível efetuar o login, tente novamente')
            setLoading(false)
        });
    }

    const afterLogin = (res) => {

        const obj = {
            token: res?.api_key,
            user_id: res.user_id,
            email: res.profile.email,
            nickname: res.profile.nickname,
            mobile_verified: res.profile.mobile_verified,
            avatar: res.profile.profile_picture?.thumbnail,
            name: (res.profile.first_name + ' ' + res.profile.last_name),
            gender: res.profile.gender,
            phone: res.profile.cellphone_number,
            cpf: res.profile.cpf,
            addresses: res.addresses,
            birthday: res.profile.birthday,
        };
        login(obj);
        history.push('/painel')
        closeModal()
        setLoading(false)
    }

    return (
        <Modal show={openModal} onHide={closeModal} size={"lg"} scrollable>

            <Modal.Body className='p-0 w-100'>
                <Modal.Header className='border-0 p-3 position-absolute' style={{right:0}} closeButton/>
                <div className='d-flex flex-column flex-lg-row'>
                    <Col md={12} lg={4} className='bg-primary'>
                        <div className=' fs-3 p-3 h-100 text-white'>
                            <div className='d-flex justify-content-center align-items-center h-75'>
                                <span>
                                    Seja bem-vindo(a) ao <strong>{Env.application_title}</strong>
                                </span>
                            </div>

                            <div className='d-flex justify-content-center align-items-end fs-6 text-lg-end h-25'>
                                Acesse e busque por prestadores de serviço!
                            </div>
                        </div>

                    </Col>

                    <Col md={12} lg={8} className='p-3'>
                        <Container className='d-flex flex-column gap-3'>
                            <div className='text-center'>
                                <img style={{maxWidth: 250}} src={Images.logo} alt='logo'/>
                            </div>

                            <form onSubmit={handleSubmit(handleLogin)} className='d-flex flex-column gap-3'>
                                <Col>
                                    <InputHF label='Email ou CPF' error={errors?.email_or_cpf?.message} register={register('email_or_cpf')} key='email_or_cpf'/>
                                </Col>

                                <Col>
                                    <InputHF label='Senha' error={errors?.password?.message} register={register('password')} key='password' isPassword/>
                                </Col>

                                <div className='text-end'>Esqueceu a senha? <span className='btn-link link-info cursor-pointer' onClick={() => changeStepModal('forget')}>Clique aqui para recuperar</span></div>
                                <div className='d-flex justify-content-between'>
                                    <Col md={4}>
                                        <button type='button' className='btn btn-link hover-gray' onClick={()=> changeStepModal('register')}>
                                            Criar conta
                                        </button>
                                    </Col>

                                    <Col xs={6} md={6}>
                                        <Button type='submit' className='w-100' variant='primary' disabled={loading}>
                                            <span className='de-flex gap-2'>{loading && <Spinner animation='border' size='sm'/>}Entrar</span>
                                        </Button>
                                    </Col>
                                </div>
                            </form>
                        </Container>
                    </Col>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LoginModal
