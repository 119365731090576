import React from "react";
import {Button, Modal} from "react-bootstrap";
import useApi from "../../../hooks/Api";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast, Toaster} from "react-hot-toast";
import NumberFormat from "react-number-format";
import LabelWithLibs from "../../../components/Generic/LabelWithLibs";
import {FaIdCard} from "react-icons/fa";
import useAuth from "../../../hooks/Auth";

const DisableAccountModal = ({openModal, closeModal, onError, profile}) => {
    const {logoutWithoutApi} = useAuth();
    const api = useApi();

    const schema = yup.object({
        cpf: yup.string().min(11, 'O CPF precisa de no mínimo 11 caracteres').required('Insira um CPF válido')
    });

    const {handleSubmit, setValue, formState: { errors },} = useForm({
        resolver: yupResolver(schema),
    });

    const disableAccount = (data) => {
        if(data.cpf === profile.cpf) {
            api.post('access/disable-account', data , () => {
                toast.success('Sua conta foi desabilitada, para habilitar novamente entre em contato conosco!', {
                    duration: 4000,
                });
                logoutWithoutApi();
            }, (e) => {
                console.log(e);
                toast.error(e.message || 'Não foi possível desabilitar sua conta, tente novamente');
            });
        } else {
            toast.error('Por favor, para desabilitar insira o seu CPF');
        }
    };

    return (
        <Modal show={openModal} onHide={closeModal}>
            <Modal.Header className='border-0 p-0 pt-3 pe-3' closeButton/>
            <Modal.Body>
                <Toaster/>
                <form onSubmit={handleSubmit(disableAccount, onError)} className='d-flex flex-column gap-3'>
                    <div className='fs-4 text-center fw-bold'>
                        Você está prestes a desabilitar a sua conta, você perderá acesso a todos os seus dados!
                    </div>

                    <div className='form-floating'>
                        <NumberFormat className={`${errors?.cpf?.message ? 'form-control is-invalid' : 'form-control'}`}
                                      placeholder='CPF'
                                      onValueChange={(e) => setValue('cpf', e.value)}
                                      format={'###.###.###-##'}
                                      mask="_"
                        />
                        <LabelWithLibs label='CPF' labelIcon={<FaIdCard size={20}/>} className={`${errors?.cpf?.message ? 'invalid-feedback' : ''}`}/>
                        {errors?.cpf?.message &&
                            <div className={`${errors?.cpf?.message ? 'invalid-feedback' : ''}`}>
                                {errors?.cpf?.message}
                            </div>
                        }
                    </div>

                    <Button variant='danger' type='submit'>
                        Desabilitar
                    </Button>
                </form>
            </Modal.Body>
        </Modal>

    )
}

export default DisableAccountModal