import React, {useEffect}    from 'react';
import {Link} from "react-router-dom";
import {Images} from "../../helpers/Images";
import {Col, Container} from "react-bootstrap"
import {Env} from "../../helpers/Env";

const PrivacyPage = () => {

    useEffect(() => {
        window.scrollToElement('body');
    },[]);

    return (
        <div>
            <Container style={{padding:16, textAlign:"justify"}}>
                    <div className="item" style={{textAlign: 'center'}}>
                        <Link to="/"><img style={{height: 100}} src={Images.logo} alt="logo" className="logo"/></Link>
                    </div>

                    <div className='border-bottom'>
                        <div style={{fontSize: 22, fontWeight: 'bold'}}> TERMOS DE USO </div>
                        <div className='d-flex flex-column gap-4 my-4'>
                            <div>
                                O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> é um programa do Governo do Estado de Rondônia, cuja plataforma tecnológica é de propriedade da empresa Bluetrix Tecnologia Ltda (CNPJ: 25.076.424/0001-60) ("Bluetrix"), tendo por objetivo promover a aproximação entre propensos Usuários contratantes e Usuários candidatos possivelmente interessados em uma oportunidade de empregabilidade oferecida pelo Usuário contratante.
                            </div>
                            <div>
                                Os presentes Termos aplicam-se especificamente à plataforma <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , englobando todos os seus subsistemas.
                            </div>
                            <div>
                                Estes Termos poderão ser atualizados, a qualquer tempo, pela Bluetrix, mediante aviso no site e/ou por e-mail, se o Usuário tiver optado por receber comunicações da plataforma.
                            </div>
                        </div>
                    </div>

                    <div className='border-bottom d-flex flex-column gap-4 my-4'>
                        <div>1. CONDIÇÕES GERAIS DE ACESSO E UTILIZAÇÃO DO {Env.application_title_uppercase} </div>
                        <div>1.1 Da utilização do {Env.application_title_uppercase}  </div>
                        <div className='mb-4'>
                            O acesso e a utilização do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> pelo Usuário têm, como regra, caráter tanto gratuito quanto oneroso, de acordo com as formas e valores previamente publicados e determinados.
                        </div>
                    </div>

                    <div className='border-bottom d-flex flex-column gap-4 my-4'>
                        <div>
                            1.2 Registro de Usuário
                        </div>
                        <div>
                            Para utilização da plataforma e funcionalidades do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , será exigida prévia inscrição ou prévio registro do Usuário para acesso. Nestes casos, o acesso à Plataforma <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> somente será liberado após o preenchimento de cadastro pelo Usuário com as informações requeridas.
                        </div>
                        <div>
                            Toda informação fornecida pelo Usuário por meio do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> deverá ser verdadeira. Assim, o Usuário garante a autenticidade de todos os dados que informar durante o preenchimento dos respectivos formulários, de modo que o Usuário poderá exercer o seu direito de correção de dados incompletos, inexatos ou desatualizados, para manter toda a informação fornecida à plataforma permanentemente atualizada, de maneira que sempre reflita os dados reais do Usuário.
                        </div>
                        <div className='mb-4'>
                            Em qualquer circunstância, o Usuário será o único responsável por declarações falsas ou inexatas que prestar e que vierem a causar prejuízos ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> ou a terceiros
                        </div>
                    </div>

                    <div className='border-bottom d-flex flex-column gap-4 my-4'>
                        <div>
                            1.3 Termos específicos para utilização dos subsistemas
                        </div>
                        <div className='mb-4'>
                            Para cada funcionalidade disponibilizada pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> é disponibilizado ao Usuário, de forma clara e objetiva, um termo de uso específico contendo as regras de utilização, documento este cuja aceitação (opt-in) será exigida do Usuário para que usufrua das funcionalidades, enquanto for de seu interesse.
                        </div>
                    </div>

                    <div className='border-bottom d-flex flex-column gap-4 my-4'>
                        <div>
                            1.4 Menores de Idade
                        </div>
                        <div>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> é direcionado a pessoas capazes e suas funcionalidades e soluções não são direcionadas a e não devem ser utilizadas por Usuários absolutamente incapazes (menores de 16 anos). De modo a evitar o acesso indevido, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> avisa claramente que suas funcionalidades são voltadas a Usuários representantes legais maiores de 16 (dezesseis) anos, desde que emancipados, pelo que será solicitado ao Usuário que indique sua data de nascimento, para fins de verificação da classificação etária.
                        </div>
                        <div className='mb-4'>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , no entanto, sob hipótese alguma, se responsabiliza pelo acesso indevido a sua plataforma, decorrente de informações incorretas ou inverídicas fornecidas pelo Usuário ou do desrespeito à classificação etária indicada.
                        </div>
                    </div>

                    <div className='border-bottom d-flex flex-column gap-4 my-4'>
                        <div>
                            1.5 Obrigação de utilizar a plataforma corretamente
                        </div>
                        <div>
                            Ao utilizar o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , o Usuário se compromete a respeitar a legislação brasileira vigente, bem como todo o quanto disposto nos presentes Termos, não devendo produzir, disponibilizar, divulgar ou transmitir qualquer conteúdo que:
                        </div>

                        <div className='border-bottom '>
                            <div className='mb-4'>
                                a) Seja contrário a qualquer norma da legislação brasileira, bem como à moral e aos bons costumes geralmente aceitos;
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                b) Incentive a discriminação, o ódio ou a violência contra pessoas ou grupos em razão de orientação sexual, gênero, raça, religião, deficiência, nacionalidade ou por qualquer outro motivo;
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                c) Incorporem, ponham à disposição ou permitam acessar produtos, elementos, mensagens e/ou serviços ilegais, violentos, pornográficos, degradantes ou, em geral, contrários à lei, à moral e aos bons costumes geralmente aceitos ou à ordem pública;
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                d) Sejam falsos, ambíguos, inexatos, exagerados ou extemporâneos, de forma que possam induzir a erro sobre seu objeto ou sobre as intenções ou propósitos do comunicante;
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                e) Sejam protegidos por quaisquer direitos de propriedade intelectual ou industrial pertencentes a terceiros, sem que o Usuário tenha obtido previamente dos seus titulares a autorização necessária para levar a cabo o uso que efetuar ou pretender efetuar;
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                f) Incorporem vírus ou outros elementos físicos ou eletrônicos que possam causar dano ou impedir o normal funcionamento da rede, do sistema ou de equipamentos informáticos (“hardware” e “software”) de terceiros, ou que possam causar dano aos documentos eletrônicos e arquivos armazenados nestes equipamentos informáticos;
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                g) Provoquem, por suas características (tais como forma, extensão etc.) dificuldades no normal funcionamento do Serviço;
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                h) Envios de materiais não solicitados às pessoas físicas, Usuários candidatos, sejam eles cadastrados no SINE (Sistema Nacional de Empregos), SINE Rondônia/SEDI (Superintendência estadual de Desenvolvimento Econômico e Infraestrutura) ou diretamente no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>, possivelmente interessadas em uma oportunidade de empregabilidade oferecida pelo Usuário contratante; ou,
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                i) Utilizar os dados de contato das pessoas físicas, Usuários candidatos, sejam eles cadastrados no SINE (Sistema Nacional de Empregos), SINE Rondônia/SEDI (Superintendência estadual de Desenvolvimento Econômico e Infraestrutura) ou diretamente no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>, possivelmente interessadas em uma oportunidade de empregabilidade oferecida pelo Usuário contratante, para utilização divergentes à que se propõe a plataforma <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>.<br/>
                            </div>
                            <div className='mb-4'>
                                Em qualquer circunstância, o Usuário é o único responsável pelo uso que fizer do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , bem como por qualquer conteúdo por ele inserido.
                            </div>
                        </div>
                    </div>

                    <div className='border-bottom'>
                        <div className='mb-4'>
                            1.6 Utilização Correta do Conteúdo e Propriedade Intelectual
                        </div>
                        <div className='mb-4'>
                            O Usuário contratante se compromete a utilizar o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , notadamente os dados de pessoas físicas possivelmente interessadas por oportunidades de empregabilidade por ele publicadas, de acordo com todo o ordenamento jurídico, com a moral e os bons costumes geralmente aceitos, com os presentes Termos e com as Condições Particulares de cada Página.
                        </div>
                        <div className='mb-4'>
                            Os conteúdos disponibilizados ao Usuário contratante que envolvam dados pessoais, sensíveis ou não, de Usuários candidatos possivelmente interessados em oportunidades de empregabilidade oferecidas por ele deverão ser utilizadas com esta estrita funcionalidade, vedado a replicação ou reprodução para integrar base de dados própria do Usuário contratante, podendo o Usuário estar sujeitos às sanções civis e criminais correspondentes, nos termos das Leis 9.610/98, 9.609/98 e 9.279/96.
                        </div>
                        <div className='mb-4'>
                            Os demais conteúdos, exceto os dados pessoais disponibilizados através do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>, são de propriedade do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , de seus titulares ou de terceiros que autorizaram sua utilização no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , e estão protegidos por leis e tratados internacionais, sendo vedada sua cópia, reprodução, ou qualquer outro tipo de utilização, ficando os infratores sujeitos às sanções civis e criminais correspondentes, nos termos das Leis 9.610/98, 9.609/98 e 9.279/96.
                        </div>
                        <div className='mb-4'>
                            O Usuário contratante deverá se abster de obter, ou de tentar obter, os Conteúdos por meios distintos daqueles que, em cada caso, tenham sido colocados à disposição para tais propósitos
                        </div>
                    </div>

                    <div className='border-bottom d-flex flex-column gap-4 my-4'>
                        <div>
                            1.7 Política Anti-Spamming do {Env.application_title_uppercase}
                        </div>
                        <div>
                            Por meio deste documento o Usuário contratante se obriga a abster-se de:
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                a) obter dados com finalidade publicitária e remeter publicidade de qualquer classe com finalidade de venda ou outras de natureza comercial sem a prévia solicitação e consentimento do Usuário candidato;
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                b) remeter a um grupo de pessoas quaisquer mensagens não solicitadas nem consentidas previamente;
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                c) enviar cadeias de mensagens eletrônicas não solicitadas nem previamente consentidas;
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                d) utilizar listas de distribuição a que se pode ter acesso através do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase} </strong> para a realização das atividades descritas nos itens acima; e
                            </div>
                        </div>
                        <div className='border-bottom'>
                            <div className='mb-4'>
                                e) colocar à disposição de terceiros, com qualquer finalidade, dados captados a partir do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>.
                            </div>
                            <div className='mb-4'>
                                O Usuário candidato ou terceiros prejudicados pela recepção de mensagens não solicitados, relacionadas ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase} </strong> e dirigidas a pluralidade de pessoas poderão efetuar sua reclamação por meio do e-mail {Env.support_email}.
                            </div>
                        </div>
                    </div>

                    <div className='border-bottom d-flex flex-column gap-4 my-4'>
                        <div>
                            1.8 Introdução de “hiperlinks” que permitam o acesso ao {Env.application_title_uppercase}
                        </div>
                        <div>
                            O Usuário que se propuser a estabelecer hiperlink entre sua página web ou ainda compartilhar links do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> (“Hiperlink”) deverá cumprir as seguintes condições:
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                a) o “Hiperlink” permitirá única e exclusivamente o acesso à homepage (página de início do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> ) ou à página específica do conteúdo que se queira interligar, mas não poderá reproduzi-la de qualquer forma;
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                b) não declarar nem fazer entender que o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> teria autorizado o “Hiperlink” ou que teria supervisionado ou assumido, sob qualquer forma, responsabilidade sobre os serviços oferecidos ou colocados à disposição na página web em que for estabelecido o “Hiperlink”;
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                c) com exceção dos sinais que formarem parte do “Hiperlink”, a página web em que se estabelecer o “Hiperlink” não conterá nenhuma marca, nome comercial, logotipo, slogan, look and feel ou quaisquer outros sinais pertencentes ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> ;
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                d) a página web em que estabelecer o “Hiperlink” não deverá conter informações ou conteúdos ilícitos, contrários à moral, aos bons costumes ou à ordem pública, bem como não conterá conteúdos contrários aos direitos de terceiros.
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                e) não criar um frame sobre o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , exceto se houver autorização expressa que possibilite inclusive a interligação via interface de programação de aplicações (“API”);
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                f) não realizar manifestações ou indicações falsas, inexatas ou incorretas sobre o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , seus administradores, empregados, ou Plataformas a ele vinculadas.
                            </div>
                            <div className='mb-4'>
                                A disponibilização de um “Hiperlink” não implica, em qualquer hipótese, a existência de relações entre a <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> e o proprietário da página web que o contiver, nem a aceitação ou aprovação da <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> acerca de seus conteúdos ou serviços.
                            </div>
                        </div>


                    </div>

                    <div className='border-bottom border-1 mb-4'>
                        <div className='mb-4'>2 DURAÇÃO E FINALIZAÇÃO</div>
                        <div className='mb-4'>
                            O acesso ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> é concedido aos Usuários por prazo indeterminado, podendo, no entanto, ser disponibilizado de maneira temporária, de acordo com as campanhas de empregabilidade lançadas pelo Poder Público. Ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , no entanto, é facultado dar por terminado, suspender ou interromper unilateralmente, a qualquer momento e sem necessidade de prévio aviso, o acesso à plataforma, sem que qualquer indenização seja devida ao Usuário.
                        </div>
                        <div className='mb-4'>
                            A Bluetrix se reserva, ainda, no direito de recusar ou retirar o acesso ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , a qualquer momento e sem necessidade de prévio aviso, por iniciativa própria ou por exigência de um terceiro, àqueles Usuários que descumprirem estes Termos. Nesta hipótese, caso hajam créditos, valores pecuniários ou quaisquer outros ativos equivalentes em poder do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>, ainda que somente dados pessoas sensíveis ou não de acordo com a política de privacidade, estes serão devolvidos aos seus titulares, em até 30 dias úteis.
                        </div>
                    </div>

                    <div className='border-bottom border-1 mb-4'>
                        <div className='mb-4'>
                            3 EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE
                        </div>
                        <div className='mb-4'>
                            Em decorrência de questões técnicas e operacionais, a <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> não pode garantir a sua disponibilidade, infalibilidade e continuidade de funcionamento, nem garante a utilidade do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> para a realização de qualquer atividade em concreto. Desse modo, a Bluetrix não será, em hipótese alguma, responsável por quaisquer danos decorrentes da interrupção de seu acesso, ou por falhas em seu funcionamento, nem se responsabiliza pela defraudação da utilidade que os Usuários possam ter atribuído à plataforma, pela sua falibilidade, nem por qualquer dificuldade de acesso.
                        </div>
                        <div className='mb-4'>
                            A Bluetrix utiliza as melhores práticas recomendadas de mercado para manter seguros todos os dados inseridos por seus Usuários, no entanto não garante que terceiros não autorizados não farão uso de meios ilícitos para obter indevidamente tais informações.
                        </div>
                         <div className='mb-4'>
                             Assim, a Bluetrix se exime de responsabilidade pelos danos e prejuízos de toda natureza que possam decorrer do conhecimento que terceiros não autorizados possam ter de quaisquer das informações repassadas, em decorrência de falha exclusivamente atribuível aos Usuários ou a terceiros que fujam a qualquer controle razoável da Bluetrix.
                         </div>
                         <div className='mb-4'>
                             A Bluetrix não garante a ausência de vírus, bem como de outros elementos nocivos que possam produzir alterações nos sistemas informáticos dos Usuários (software e hardware) ou nos documentos eletrônicos armazenados no sistema informático, eximindo-se de qualquer responsabilidade pelos danos e prejuízos que possam decorrer de quaisquer elementos nocivos.
                         </div>
                        <div className='mb-4'>
                            A Bluetrix não verifica ou controla o uso que os Usuários fazem de sua plataforma. Dessa forma, a Bluetrix se exime de qualquer responsabilidade pelos danos e prejuízos de toda natureza que possam decorrer de sua utilização ou que possam ser devidos à falta de veracidade, vigência, completude e/ou autenticidade do conteúdo lá disponibilizado, bem como da informação que os Usuários proporcionam a outros Usuários sobre si mesmos e, em particular, ainda que não de forma exclusiva, pelos danos e prejuízos de toda a natureza que possam ser devidas à suplantação da personalidade efetuada por um Usuário em qualquer classe de comunicação realizada por meio do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> .
                        </div>
                    </div>

                    <div className='border-bottom border-1 mb-4'>
                        <div className='mb-4'>
                            4 PROCEDIMENTO EM CASO DE REALIZAÇÃO DE ATIVIDADES DE CARÁTER ILÍCITO
                        </div>
                        <div className='mb-4'>
                            Na hipótese de um Usuário ou um terceiro considerar que existem informações, fatos ou circunstâncias que constituem atividade ilícita no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , especialmente em caso de violação de direitos de propriedade intelectual ou outros direitos, deverá enviar comunicação à Bluetrix contendo os seguintes dados:
                        </div>
                        <div className='border-bottom border-1 mb-4'>
                            <div className='mb-4'>
                                a) dados pessoais: nome, endereço, número de telefone e endereço de correio eletrônico do reclamante;
                            </div>
                        </div>
                        <div className='border-bottom border-1 mb-4'>
                            <div className='mb-4'>
                                b) especificação da suposta atividade ilícita ocorrida no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> e, em particular, quando se tratar de suposta violação de direitos autorais, indicação precisa e completa dos conteúdos protegidos e supostamente infringidos;
                            </div>
                        </div>
                        <div className='border-bottom border-1 mb-4'>
                            <div className='mb-4'>
                                c) fatos ou circunstâncias que revelam o caráter ilícito de tal atividade; e
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                d) se for o caso, declaração expressa e clara de que a utilização dos conteúdos foi realizada sem o consentimento do titular dos direitos de propriedade intelectual supostamente infringidos.
                            </div>
                            <div className='mb-4'>
                                Sempre de acordo com a legislação em vigor e com as circunstâncias técnicas de convênios entre o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> e parceiros, a partir da ciência, a Bluetrix poderá excluir conteúdo acusado como ilícito, assim como fornecer informações às autoridades competentes, mediante o competente requerimento, com a finalidade de viabilizar a apuração de condutas ilegais.
                            </div>

                        </div>
                    </div>

                    <div className='border-bottom border-1 mb-4'>
                        <div className='mb-4'>
                            5 DISPOSIÇÕES GERAIS
                        </div>
                        <div className='mb-4'>
                            Se qualquer parte destes Termos for considerada inválida ou inexequível, tal trecho deve ser interpretado de forma consistente com a lei aplicável, para refletir, na medida do possível, a intenção original das partes, e as demais disposições permanecerão em pleno vigor e efeito.
                        </div>
                        <div className='mb-4'>
                            A falha do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> em exigir quaisquer direitos ou disposições dos presentes Termos não constituirá renúncia, podendo exercer regularmente o seu direito, dentro dos prazos legais.
                        </div>
                    </div>

                    <div className='border-bottom border-1 mb-4'>
                        <div className='mb-4'>
                            6 NOTIFICAÇÕES
                        </div>
                        <div className='mb-4'>
                            <div className='mb-4'>
                                Todas as notificações e comunicações (doravante, as “Notificações”) por parte dos Usuários ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase} </strong> são consideradas eficazes, para todos os efeitos, quando forem dirigidas por meio do e-mail {Env.support_email}, e suas respostas serão efetuadas ao e-mail remetente ou através das seguintes formas:
                            </div>
                            <div className='border-bottom border-1 mb-4'>
                                <div className='mb-4'>
                                    a) envio de carta ao domicílio do Usuário quando este tiver fornecido um endereço válido ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> ;
                                </div>
                            </div>
                            <div className='border-bottom border-1 mb-4'>
                                <div className='mb-4'>
                                    b) envio de mensagem por correio eletrônico a qualquer dos endereços fornecidos pelo Usuário;
                                </div>
                            </div>
                            <div className='border-bottom border-1 mb-4'>
                                <div className='mb-4'>
                                    c) comunicação telefônica ao número fornecido pelo Usuário; e,
                                </div>
                            </div>
                            <div>
                                <div className='mb-4'>
                                    d) mediante a inclusão de mensagens em qualquer área da plataforma do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> .
                                </div>
                                <div className='mb-4'>
                                    Neste sentido, todas as Notificações que o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> realizar serão consideradas válidas quando efetuadas empregando os dados e através dos meios anteriormente destacados. Para estes efeitos, o Usuário declara que todos os dados fornecidos são válidos e corretos, comprometendo-se a comunicar ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> todas as mudanças relativas aos dados de notificação.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div className='mb-4'>
                            7 LEGISLAÇÃO E FORO APLICÁVEIS
                        </div>
                        <div className='mb-4'>
                            Esses Termos serão regidos, interpretados e executados de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente o Foro da Comarca de Santana do Parnaíba (SP), para dirimir qualquer dúvida decorrente deste instrumento.
                        </div>
                        <div className='mb-4'>
                            <strong style={{fontWeight: 'bold'}}> Última atualização: 30 de Agosto de 2021. </strong>
                        </div>
                    </div>
                </Container>

            <Container style={{padding:16, textAlign:"justify"}}>
                <div style={{textAlign: 'center'}}>
                    <Link to="/"><img style={{height: 100}} src={Images.logo} alt="logo" className="logo"/></Link>
                </div>

                <div className='text-justify'>
                    <div style={{fontSize: 22, fontWeight: 'bold'}}>
                        POLÍTICA DE PRIVACIDADE
                    </div>

                    <div className='d-flex flex-column gap-4 my-4 '>
                        <div className='text-justify'>
                            A empresa Bluetrix Tecnologia Ltda (CNPJ: 25.076.424/0001-60), com sede em Santana de Parnaíba / SP (“Bluetrix”), detentora da Plataforma <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> (“<strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>”), entende como sensível e relevante os registros eletrônicos e dados pessoais deixados por você (“Usuário”) na utilização desta e de outros serviços e plataformas de sua propriedade (“Plataformas”), servindo a presente Política de Privacidade (“Política”) para regular, de forma simples, transparente e objetiva, quais dados e informações serão obtidos, assim como quando os mesmos poderão ser utilizados.
                        </div>
                        <div className='text-justify'>
                            A presente Política se aplica especificamente ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , englobando todos os seus subsistemas.
                        </div>
                        <div className='text-justify'>
                            Para melhor ilustrar a forma como realizamos o tratamento de dados, apresentamos um resumo de nossa Política de Privacidade e Proteção de Dados Pessoais (“Política”):
                        </div>
                    </div>

                    <div className='border-bottom border-1 mb-4'>
                        <strong style={{fontWeight: 'bold'}}> QUADRO DE RESUMO </strong>

                        <div style={{border: '1px solid gainsboro', display: 'flex', alignItems:"center"}}>
                            <Col xs={4} md={3} className='p-3 text-center'>
                                Agente de tratamento
                            </Col>
                            <Col xs={8} md={9} className='p-3' style={{borderLeft: '1px solid gainsboro'}}>
                                Bluetrix Tecnologia Ltda. <br/>
                                CNPJ: 25.076.424/0001-60<br/>
                                Endereço:  AV YOJIRO TAKAOKA, 4384, SALA 701 CJ 5605, na cidade de Santana do Parnaíba/SP
                            </Col>
                        </div>

                        <div style={{border: '1px solid gainsboro', display: 'flex', alignItems:"center"}}>
                            <Col xs={4} md={3} className='p-3 text-center'>
                                Papel no tratamento
                            </Col>

                            <Col xs={8} md={9} className='p-3' style={{borderLeft: '1px solid gainsboro'}}>
                                Predominantemente Processadora
                            </Col>
                        </div>

                        <div style={{border: '1px solid gainsboro', display: 'flex', alignItems:"center"}}>
                            <Col xs={4} md={3} className='p-3 text-center'>
                                Natureza dos dados tratados
                            </Col>

                            <Col xs={8} md={9} className='p-3' style={{borderLeft: '1px solid gainsboro'}}>
                                Dados pessoais, inclusive sensíveis, coletados diretamente do titular de dados ou compartilhados pelo sistema SINE/ME, através de convênio CODEFAT 826/2019.
                            </Col>
                        </div>

                        <div style={{border: '1px solid gainsboro', display: 'flex', alignItems:"center"}}>
                            <Col xs={4} md={3} style={{wordBreak:"break-word"}} className='p-3 text-center'>
                                Finalidade como processadora
                            </Col>

                            <Col xs={8} md={9} className='p-3' style={{borderLeft: '1px solid gainsboro'}}>
                                Utilizar dados pessoais, sensíveis ou não, advindos da base do órgão público a que estiver vinculado ou do SINE/ME para fins de disponibilização aos recrutadores e empregadores cadastrados no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>, com a comprovação auditável do(s) interessados(s) e da justificativa do ciclo do dado acessado, desde o acesso, utilização, finalidade, anonimização e eliminação.
                            </Col>
                        </div>

                        <div style={{border: '1px solid gainsboro', display: 'flex', alignItems:"center"}}>
                            <Col xs={4} md={3} style={{wordBreak:"break-word"}} className='p-3 text-center'>
                                Base Legal
                            </Col>

                            <Col xs={8} md={9} className='p-3' style={{borderLeft: '1px solid gainsboro'}}>
                                <strong> Contrato 553/PGE-2021 de 30/08/2021 </strong>
                                <br/>
                                Resolução CODEFAT 826/2019.
                                Dados de identificação digital: Art. 15, Marco Civil da Internet.
                                Dados cadastrais (Enquanto durar a relação e não houver pedido de apagamento ou revogação de consentimento): artigo 7º; III; art. 9, II; art. 26, inciso IV, §1º todos da Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados – LGPD”)
                            </Col>
                        </div>

                        <div style={{border: '1px solid gainsboro', display: 'flex', alignItems:"center"}}>
                            <Col xs={4} md={3} style={{wordBreak:"break-word"}} className='p-3 text-center'>
                                Compartilhamento
                            </Col>

                            <Col xs={8} md={9} className='p-3' style={{borderLeft: '1px solid gainsboro'}}>
                                Pessoas Físicas e Jurídicas, públicas ou privadas, com finalidade restrita a oferecimento de oportunidades de empregabilidade e correlatas.
                            </Col>
                        </div>

                        <div style={{border: '1px solid gainsboro', display: 'flex', alignItems:"center"}}>
                            <Col xs={4} md={3} className='p-3 text-center'>
                                Proteção de Dados
                            </Col>

                            <Col xs={8} md={9} className='p-3' style={{borderLeft: '1px solid gainsboro'}}>
                                Medidas de segurança, técnicas e administrativas adequadas, de acordo com as melhores práticas.
                            </Col>
                        </div>

                         <div style={{border: '1px solid gainsboro', display: 'flex', alignItems:"center"}}>
                            <Col xs={4} md={3} className='p-3 text-center'>
                                Seus direitos
                            </Col>

                            <Col xs={8} md={9} className='p-3' style={{borderLeft: '1px solid gainsboro'}}>
                                Confirmação da existência de tratamento, acesso, correção e apagamento
                            </Col>
                        </div>

                        <div className='my-4'>
                            <div className='text-justify'>
                                Esta Política poderá ser atualizada, a qualquer tempo, pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , mediante aviso no site e/ou por e-mail, se o Usuário tiver optado por receber comunicações do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> .
                            </div>
                        </div>
                    </div>

                    <div style={{textAlign: "justify"}} className='d-flex flex-column gap-4 border-bottom border-1 mb-4'>
                        <div>
                            1. QUAIS DADOS UTILIZAMOS
                        </div>
                        <div>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> poderá coletar as informações inseridas ativamente pelo Usuário no momento do cadastro e, ainda, informações coletadas automaticamente quando da utilização do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> e seus subsistemas, bem como da rede, como, por exemplo, identificação do estabelecimento comercial utilizado, IP com data e hora da conexão, entre outras.
                        </div>
                        <div>
                            Há, assim, o tratamento de dois tipos de dados pessoais: (i) aqueles fornecidos pelo próprio Usuário; e (ii) aqueles coletados automaticamente, através de convênios realizados pelo Poder Público.
                        </div>
                        <div>
                            <strong style={{ fontWeight: 'bold'}}> (i) Informações fornecidas pelo Usuário: </strong> o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> coleta todas as informações inseridas ativamente pelo Usuário em sua plataforma, tais como e não se limitando a nome completo, CPF, endereço, e-mail, número de telefone celular, gênero, data de nascimento, cidade e estado, serviços que oferece, informações sobre disponibilidade, quando do preenchimento de formulários no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> pelo Usuário. O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> fará uso dessas informações para viabilizar a comprovação ao Poder Público acerca da utilização dos dados disponíveis em sua base, possibilitando, com isso, a auditoria e comprovação de sua utilização, em todo o ciclo de usabilidade.
                        </div>
                        <div>
                            <strong style={{ fontWeight: 'bold'}}> (ii) Dados coletados automaticamente: </strong> o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> também coleta uma série de informações de modo automático, tais como, mas não se limitando a: características do dispositivo de acesso, do navegador, IP (com data e hora), porta lógica de origem do IP, informações sobre cliques, páginas acessadas, as páginas seguintes acessadas após a saída das Páginas, ou qualquer termo de procura digitado nos sites ou em referência a estes, dentre outros. Para tal coleta, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> fará uso de algumas tecnologias padrões, como cookies, pixel tags, beacons e local shared objects, que são utilizadas com o propósito de melhorar a experiência de navegação do Usuário no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , de acordo com seus hábitos e suas preferências.
                        </div>
                        <div>
                            É possível desabilitar, por meio das configurações de seu navegador de internet, a coleta automática de informações por meio de algumas tecnologias, como cookies e caches, bem como em nosso próprio website, especificamente quanto aos cookies. No entanto, o Usuário deve estar ciente de que, se desabilitadas estas tecnologias, alguns recursos oferecidos pelo site, que dependem do tratamento dos referidos dados, poderão não funcionar corretamente.
                        </div>
                        <div>
                            Além disso, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> poderá promover pesquisas de satisfação e de preferência durante sua utilização, coletando estas informações para melhorar cada dia mais os serviços, para compreender melhor as suas preferências e avaliar a qualidade e suas impressões sobre as soluções ofertadas, bem como para fins estatísticos e publicitários. Para esta última hipótese, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> disponibiliza funcionalidades para que o Usuário autorize (ou não) o tratamento de seus dados, sendo certo que tal aceitação não é condição para cadastramento do Usuário.
                        </div>
                        <div>
                            As informações coletadas poderão ser compartilhadas pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> nas seguintes hipóteses: (i) com demais órgãos públicos e parceiros, quando forem necessárias para a adequada prestação dos serviços objeto de suas atividades; (ii) para proteção dos interesses do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> em qualquer tipo de conflito; (iii) mediante decisão judicial ou requisição de autoridade competente.
                        </div>
                        <div>
                            Ainda, no âmbito interno esclarecemos que suas informações também poderão ser compartilhadas com empresas provedoras de infraestrutura tecnológica e operacional necessária para as atividades do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , como intermediadoras de pagamento e provedores de serviço de armazenamento de informações.
                        </div>
                        <div className='mb-4'>
                            O Usuário poderá acessar, atualizar e adicionar seus dados, bem como poderá solicitar a exclusão dos seus dados coletados pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , através do formulário disponível em sua área logada no sistema e também através do e-mail {Env.support_email} Iremos nos esforçar para respondê-lo no menor tempo possível, respeitando-se os prazos de guarda estabelecidos pela legislação.
                        </div>
                    </div>

                    <div style={{textAlign: "justify"}} className='d-flex flex-column gap-4 border-bottom border-1 mb-4'>
                        <div>
                            2 COMO UTILIZAMOS OS DADOS
                        </div>
                         <div>
                             As informações coletadas pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> têm como finalidade o estabelecimento de vínculo contratual ou a gestão, administração, prestação, ampliação e melhoramento do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> tanto ao usuário, quanto perante este e o Poder Público e empresas eventualmente interessadas na contratação do Usuário, visando cumprir os princípios norteadores não só da administração pública, mas também do acesso à empregabilidade.
                         </div>
                         <div>
                             As informações coletadas poderão, ainda, ser utilizadas tanto para fins publicitários, como para o envio de informações de novas campanhas, produtos e benefícios do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , bem como a divulgação de eventos, ou para a realização de pesquisa de satisfação, mediante o consentimento do titular para tal.

                         </div>
                         <div>
                             O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> poderá centralizar as informações coletadas, as quais poderão ser utilizadas em seus respectivos subsistemas, respeitadas as finalidades ora dispostas e o consentimento do Usuário.

                         </div>
                        <div>
                            Caso não deseje mais receber informativos publicitários do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , ou de seus respectivos produtos, soluções e plataformas, a qualquer momento o Usuário pode contatar o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> através formulário disponível em sua área logada no sistema e também através do e-mail {Env.support_email} .

                        </div>
                        <div className='mb-4'>
                            Nestes casos, o tratamento de dados é autorizado pelo inciso III do artigo 7º, bem como do inciso IV, §1º do art. 26, ambos da Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados – LGPD”).
                        </div>
                    </div>

                    <div style={{textAlign: "justify"}} className='d-flex flex-column gap-4 border-bottom border-1 mb-4'>
                        <div>
                            3 COMO UTILIZAMOS OS COOKIES
                        </div>
                        <div>
                            Cookies são arquivos ou informações que podem ser armazenadas em seus dispositivos, quando você visita o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> . Geralmente, um cookie contém o nome do site que o originou, seu tempo de vida e um valor, que é gerado aleatoriamente
                        </div>
                        <div>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase} </strong> utiliza cookies para facilitar o uso e melhor adaptar o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase} </strong> aos seus interesses e necessidades, bem como para compilarmos informações sobre a utilização de nossos subsistemas, sites e serviços, auxiliando a melhorar suas estruturas e seus conteúdos. Os cookies também podem ser utilizados para acelerar suas atividades e experiências futuras nas Páginas.
                        </div>
                        <div className='text-center' style={{overflowX:"auto"}}>
                            <>
                                <div style={{border: '1px solid gainsboro', display: 'flex', textAlign:"center"}}>
                                    <Col  xs={4} style={{placeSelf: 'center', fontWeight: 'bold'}}>
                                        Tipos de Cookies
                                    </Col>
                                    <Col  xs={4} style={{fontWeight: 'bold', borderLeft: '1px solid gainsboro', borderRight: '1px solid gainsboro', padding: 15}}>
                                        O que eles fazem?
                                    </Col>
                                    <Col xs={4} style={{fontWeight: 'bold', placeSelf: 'center'}}>
                                        Espécies
                                    </Col>
                                </div>

                                <div style={{border: '1px solid gainsboro', display: 'flex'}}>
                                    <Col  xs={4} style={{placeSelf: 'center'}}>
                                        Necessários
                                    </Col>
                                    <Col  xs={4} style={{borderLeft: '1px solid gainsboro', borderRight: '1px solid gainsboro', padding: 15}}>
                                        Esses cookies nos ajudam a entender como os visitantes interagem com o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase} </strong> , fornecendo informações sobre as áreas visitadas, o tempo de visita ao site e quaisquer problemas encontrados, como mensagens de erro.
                                    </Col>
                                    <Col xs={4} style={{placeSelf: 'center'}}>
                                        _ga; _gid; _gat_UA-103613208-1; _gat_UA-11684720-3
                                    </Col>
                                </div>

                                <div style={{border: '1px solid gainsboro', display: 'flex'}}>
                                    <Col xs={4} style={{placeSelf: 'center'}}>
                                        Funcionais
                                    </Col>
                                    <Col xs={4} style={{borderLeft: '1px solid gainsboro', borderRight: '1px solid gainsboro', padding: 15}}>
                                        Esses cookies permitem que o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> se lembre de suas escolhas, para proporcionar uma experiência mais personalizada. Também, possibilitam que os Usuários assistam a vídeos e utilizem ferramentas sociais, campos para comentários, fóruns, entre outros.
                                    </Col>
                                    <Col xs={4} style={{placeSelf: 'center'}}>
                                        Bluetrix_idade_verificada; contrast-theme, increased-font
                                    </Col>
                                </div>

                                <div style={{border: '1px solid gainsboro', display: 'flex'}}>
                                    <Col xs={4} style={{placeSelf: 'center'}}>
                                        Marketing
                                    </Col>
                                    <Col xs={4} style={{borderLeft: '1px solid gainsboro', borderRight: '1px solid gainsboro', padding: 15}}>
                                        Esses cookies são utilizados para fornecer mais conteúdo relevante e do interesse dos Usuários. Podem ser utilizados para apresentar publicidade mais direcionada ou limitar o número que esta é veiculada, no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> . Também, permitem a medição da eficácia de uma campanha publicitária do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> .
                                        <br/><br/>
                                        Ainda, esses cookies podem ser utilizados para indicar ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> os sites que o Usuário visitou, podendo compartilhar estas informações com terceiros, tais como agências publicitárias contratadas
                                    </Col>
                                    <Col xs={4} style={{placeSelf: 'center'}}>
                                        GPS; IDE; PREF; VISITOR_INFO1_LIVE; YSC; _fbp; __trf.src; _gcl_au; _kuid_; _td; _td_global; fr
                                    </Col>
                                </div>

                            </>
                        </div>
                        <div className='d-flex flex-column gap-4 '>
                            <div>
                                Após o Usuário consentir para a utilização de cookies, quando do uso do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , será armazenado um cookie em seu dispositivo para lembrar disso na próxima sessão.
                            </div>

                            <div>
                                A qualquer momento, o Usuário poderá revogar seu consentimento quanto aos cookies, pelo que deverá apagar os cookies do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> utilizando as configurações de seu navegador de preferência. Para mais informações sobre como proceder em relação à gestão dos cookies nos navegadores:

                            </div>
                            <div>
                                <strong style={{fontWeight: 'bold'}}> Internet Explorer: </strong>
                                https://support.microsoft.com/pt-br/help/17442/windows-internetexplorer-delete-manage-cookies
                            </div>
                            <div>
                                <strong style={{fontWeight: 'bold'}}> Mozilla Firefox: </strong>
                                https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-queos-sites-usam
                            </div>
                            <div>
                                <strong style={{fontWeight: 'bold'}}> Google Chrome: </strong>
                                https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR
                            </div>
                            <div>
                                <strong style={{fontWeight: 'bold'}}> Safari: </strong>
                                https://support.apple.com/pt-br/guide/safari/sfri11471/mac
                            </div>
                            <div className='mb-4'>
                                Por fim, lembramos que, caso o Usuário não aceite alguns cookies do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase} </strong>, certos serviços poderão não funcionar de maneira ideal.
                            </div>
                        </div>
                    </div>

                    <div style={{textAlign: "justify"}} className='d-flex flex-column gap-4 border-bottom border-1 mb-4'>
                        <div>
                            4 COM QUEM (OU DE QUEM) COMPARTILHAREMOS OS DADOS
                        </div>
                        <div>
                            4.1 O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> atua mediante convênio com o Poder Público, utilizando-se inclusive como base o Sistema Nacional de Emprego – SINE/ME. Deste modo, poderá tanto obter dados diretamente das pessoas físicas interessadas em vagas de emprego ofertadas pelos Usuários contratantes/recrutadores, quanto das bases de dados dos órgãos e entidades públicas que realizem ações de empregabilidade, podendo ainda compartilhar as informações coletadas nas seguintes hipóteses:
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                i. Para proteção dos interesses do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> em qualquer tipo de conflito, incluindo ações judiciais;
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                ii. Para realização de novas campanhas, temporárias ou permanentes, promovidas pelo Poder Público, baseada em normas ou convênios específicos;
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                iii. Para continuidade dos serviços, no caso de transações e alterações societárias envolvendo o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>;

                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                iv. Para pessoas jurídicas de direito privado, mediante o consentimento do titular do dado, para oferta de vagas de emprego, bem como de cursos profissionalizantes, visando viabilizar entrevistas, ofertar vagas de emprego, capacitação e eventuais outras vantagens ao Usuário. Nesta hipótese, uma vez consentido e autorizado o compartilhamento do dado pessoal com pessoas jurídicas de direito privado, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> não será responsável pelas Políticas de Privacidade e Tratamento de Dados de terceiros e nem pelo conteúdo de quaisquer websites, conteúdos ou serviços ligados a ambientes que não os do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>.

                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                v. Para atender autoridades constituídas, mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para sua requisição.

                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                vi. Para a portabilidade de dados pessoais, no caso de solicitação de portabilidade de dados solicitada por seu titular, assim entendido como o próprio Usuário, a outras plataformas de interesse deste, nos termos do art. 18, V da Lei 13.709/18.
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                4.2 Processamento por terceiros sob diretriz do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>.  Caso empresas terceirizadas realizem, em nome do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>, o Tratamento de quaisquer Dados Pessoais que coletamos, elas respeitarão as condições aqui estipuladas e as normas de segurança da informação, obrigatoriamente.
                            </div>
                        </div>
                    </div>

                    <div style={{textAlign: "justify"}} className='d-flex flex-column gap-4 border-bottom border-1 mb-4'>
                        <div>
                            5 COMO MANTEMOS OS DADOS SEGUROS
                        </div>
                        <div>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> armazenará as informações coletadas pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> em servidores próprios ou por ela contratados.
                        </div>
                        <div>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> utiliza os meios razoáveis de mercado e legalmente requeridos para preservar a privacidade dos dados coletados através do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> . Desta forma, adota as seguintes precauções, em observância às diretrizes sobre padrões de segurança estabelecidas no Decreto nº 8.771/2016, tais como:
                        </div>

                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                i. O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> utiliza os métodos padrão e de mercado para criptografar e anonimizar os dados coletados;
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                ii. O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> possui proteção contra acesso não autorizado a seus sistemas;
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                iii. O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> somente autoriza o acesso de pessoas previamente estabelecidas ao local onde são armazenadas as informações coletadas;
                            </div>
                        </div>
                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                iv. Aqueles que entrarem em contato com as informações deverão se comprometer a manter sigilo absoluto. A quebra do sigilo acarretará responsabilidade civil e o responsável será responsabilizado nos moldes da legislação brasileira; e
                            </div>
                        </div>

                        <div>
                            v. Manutenção do inventário indicando momento, duração, identidade do funcionário, ou do responsável pelo acesso e o arquivo objeto, com base nos registros de conexão e de acesso a aplicações, conforme determinado no artigo 13 do Decreto nº 8.771/2016.
                        </div>

                        <div className='mb-4'>
                            <>
                                O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> adota os melhores esforços, no sentido de preservar a privacidade dos dados dos Usuários e da base de dados recebida mediante compartilhamento do SINE/ME. Entretanto, nenhum site é totalmente seguro e o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> não pode garantir integralmente que todas as informações que trafegam no <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> não sejam alvo de acessos não autorizados perpetrados por meio de métodos desenvolvidos para obter informações de forma indevida. Por esse motivo, nós incentivamos os Usuários a tomar as medidas apropriadas para se proteger, como, por exemplo, mantendo confidenciais todos os nomes de usuário e senhas, bem como utilizando softwares seguros de armazenamento de senhas e informações sigilosas.
                            </>
                        </div>
                    </div>

                    <div style={{textAlign: "justify"}} className='d-flex flex-column gap-4 border-bottom border-1 mb-4'>
                        <div>
                            6  RETENÇÃO DAS INFORMAÇÕES COLETADAS
                        </div>
                        <div>
                            As informações coletadas pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> serão automaticamente excluídas de seus servidores quando deixarem de ser úteis para os fins para os quais foram coletadas, ou quando o usuário solicitar a eliminação de seus dados pessoais.

                        </div>
                        <div>
                            <div className='mb-4'>
                                Sem prejuízo, as informações poderão ser conservadas para cumprimento de obrigação legal ou regulatória, transferência a terceiro – desde que respeitados os requisitos de tratamento de dados – e uso exclusivo do <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> , vedado seu acesso por terceiro, desde que anonimizadas ou pseudonimizadas.
                            </div>
                        </div>
                    </div>

                    <div style={{textAlign: "justify"}} className='d-flex flex-column gap-4 border-bottom border-1 mb-4'>
                        <div>
                            7 SEUS DIREITOS
                        </div>
                        <div>
                            7.1 Direitos Básicos do Usuário. O Usuário poderá solicitar a confirmação da existência tratamento de Dados Pessoais, além da exibição de seus Dados Pessoais, por meio do nosso Canal de Atendimento.
                        </div>
                        <div>
                            Enquanto o Usuário mantiver conta na plataforma, a correção dos seus Dados Pessoais deverá ser realizada diretamente na respectiva área logada.
                        </div>
                        <div>
                            Enquanto o Usuário mantiver conta ativa na plataforma, somente os pedidos de confirmação de existência de dados pessoais relacionados a outras espécies de relacionamento entre o Usuário e o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>, a exemplo de compartilhamento de dados com empresas ofertantes de vagas e demais vantagens, serão direcionados ao Encarregado de Dados Pessoais (Data Privacy Officer, ou “DPO”).
                        </div>
                        <div>
                            7.2. Limitação, oposição e exclusão de dados. Pelos Canais de Atendimento, o Usuário poderá também:
                        </div>



                        <div className='border-bottom border-1'>
                            <div className='mb-4'>
                                a) Manifestar sua oposição e/ou revogar o consentimento quanto ao uso de seus Dados Pessoais; ou
                            </div>
                        </div>

                        <div >
                            b) Solicitar a exclusão de seus Dados Pessoais que tenham sidos coletados pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>.
                        </div>

                        <div>
                            Se o Usuário retirar seu consentimento para finalidades fundamentais ao funcionamento adequado do site e dos serviços, os serviços tornar-se-ão indisponíveis.

                        </div>
                        <div>
                            Caso o Usuário solicite a exclusão de seus Dados Pessoais, situação em que imediatamente perderá acesso ao <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>, pode ocorrer que os Dados precisem ser mantidos por período superior ao pedido de exclusão, nos termos do artigo 16 da Lei Geral de Proteção de Dados Pessoais, para (i) cumprimento de obrigação legal ou regulatória, (ii) estudo por órgão de pesquisa, e (iii) transferência a terceiro (respeitados os requisitos de tratamento de dados dispostos na mesma Lei). Em todos os casos mediante a anonimização dos Dados Pessoais, desde que possível.

                        </div>
                        <div>
                            Findos o prazo de manutenção e a necessidade legal, os Dados Pessoais serão excluídos com uso de métodos de descarte seguro, ou utilizados de forma anonimizada para fins estatísticos.

                        </div>
                        <div>
                            Parte destes direitos poderá ser exercida diretamente pelo Usuário, a partir da gestão de informações sobre a conta, na página seus dados. As demais alterações dependerão do envio de solicitação para posterior avaliação e adoção de demais providências pelo <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong>

                        </div>

                        <div>
                            O <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> empreenderá todos os esforços para atender tais pedidos no menor espaço de tempo possível. No entanto, mesmo em caso de requisição de exclusão, será respeitado o prazo de armazenamento mínimo de informações de usuários de aplicações de Internet, determinado pela legislação brasileira.
                        </div>
                        <div>
                            Em caso de requisição de cancelamento de conta a pedido do Usuário, a qual esteja pendente de justificativa exigida para a visualização do dado da pessoa física solicitada, o <strong style={{color: '#0072d6', fontWeight: 'bold'}}> {Env.application_title_uppercase}  </strong> poderá concluí-la somente após a providência adotada pelo Usuário.
                        </div>
                        <div className='mb-4'>
                            <div>
                                Dados de contato do Encarregado de Dados:
                                <strong> Reinaldo de Andrade Silva </strong>
                            </div>

                            <div>
                                Endereço físico de contato:
                                <strong> reinaldo@bluetrix.com.br </strong>
                            </div>
                        </div>

                    </div>

                    <div style={{textAlign: "justify"}} className='d-flex flex-column gap-4 mb-4'>
                        <div>
                            8 LEGISLAÇÃO E FORO
                        </div>
                        <div>
                            Esta Política será regida, interpretada e executada de acordo com as Leis da República Federativa do Brasil, especialmente a Lei nº 13.709/2018, independentemente das Leis de outros estados ou Países, sendo competente o foro de domicílio do Usuário para dirimir qualquer dúvida decorrente deste documento.
                        </div>
                        <div>
                            <strong> Versão: 30 de Agosto de 2021. </strong>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default PrivacyPage;
