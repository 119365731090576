import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Env} from "../helpers/Env";
import useAuth from "./Auth";
import * as Sentry from "@sentry/react";

const useApi = (config?: {apiUrl?: string, headers?: Object, customReturn?: boolean, loadingControl?: boolean, initialLoading?: boolean, debounceTimer?: number}) => {
    const [loading, setLoading] = useState(typeof config?.initialLoading !== "undefined" ? config.initialLoading : false);
    const [error, setError] = useState(false);
    const auth = useAuth();


    if(!config) config = {};
    if(typeof config.loadingControl === "undefined") config.loadingControl = true;

    config.apiUrl = config.apiUrl || Env.api_url;

    const put = (path, data, cb, cbe) => {
        _fetch(path, 'PUT', data, (res) => cb(res), (res) => cbe(res))
    };

    const post = (path, data, cb, cbe) => {
        _fetch(path, 'POST', data, (res) => cb(res), (res) => cbe(res))
    };

    const get = (path, cb, cbe) => {
        _fetch(path, 'GET', null, (res) => cb(res), (res) => cbe(res))
    };

    const del = (path, data, cb, cbe) => {
        _fetch(path, 'DELETE', data || null, (res) => cb(res), (res) => cbe(res))
    };

    const _fetch = (path, method, data, cb, cbe) => {
            setLoading(true);

            const obj = {
                method: method,
                headers: config.headers || Env.header,
            }

            if( data ) {
                obj.body = JSON.stringify(data);
            }

            fetch(`${config.apiUrl}/${path}`, obj)
                .then((response) => {
                    if(config.loadingControl) setLoading(false);
                    if (response.status === 200 || response.status === 206) {
                        response.json().then((res) => {
                            cb(res);
                        })
                    } else {
                        if (response.status === 403) {
                            auth.logoutWithoutApi();
                            cbe(403);
                        } else {
                            response.json().then(res => {
                                window.gtag&& window.gtag('event', 'apiResponses', {
                                    'event_category': 'Api Reject',
                                    'event_label': 'Rota: ' + path + '|| Mensagem: ' + res.message
                                });
                                cbe(res);
                            }).catch(err => {
                                Sentry.captureException(err);
                                cbe({error: "Erro de aplicação"})
                            })
                        }
                    }
                })
                .catch(err => {
                    Sentry.captureException(err);
                    if(config.loadingControl) setLoading(false);
                    cbe({message: 'Não foi possível se conectar ao servidor, recarregue a página e tente novamente'});
            })
    };

    return { post, get, del, put, loading, error, setLoading };
};

useApi.propTypes = {
    url: PropTypes.string,
    config: PropTypes.shape({
        customReturn: PropTypes.bool,
        apiUrl: PropTypes.string,
        headers: PropTypes.object,
    })
};

export default useApi;
