import React, { useState} from 'react'
import useAuth from "../hooks/Auth";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import ModalController from "../pages/LogIntoSystem/ModalController";
import NavbarTop from "./Components/NavbarTop";
import NavbarBottom from "./Components/NavbarBottom";

const Header = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const {isLogged, logoutWithoutApi} = useAuth();

    const [step, setStep] = useState(undefined)

    const handleStepModal = (value) => {
        setStep(value)
    }


    return (
        <header>
            <NavbarTop dispatch={dispatch}
                       history={history}
                       isLogged={isLogged}
                       handleStepModal={(value) => handleStepModal(value)}
                       logoutWithoutApi={logoutWithoutApi}
            />

            <NavbarBottom history={history}
                          isLogged={isLogged}
            />

            {step &&  <ModalController typeModal={step}  handleStepModal={(e) => handleStepModal(e)}/>}
        </header>
    )
}

export default Header
