import logo_header from '../img/png/logo/geracao_servico_horizontal.png'
import logo from '../img/png/logo/geracao_servico_vertical.png'
import number_step from '../img/phone.png'
import case_img from '../img/case.png'
import menuWorker from '../img/menuWorker.png'
import gplay_icon from '../img/gplay.png'
import service_not_found from '../img/svg/serviceNotFound.svg'

import user_place_holder from '../img/photoPlaceholder.png'
import notification_card from '../img/notification_main.png'
import profile_card from '../img/profile_main.png'
import agenda_card from '../img/agenda_card.png'
import no_notification_found from '../img/svg/noNotificationsFound.svg'
import resume_not_found from '../img/empty.png'
import feedbackService from '../img/svg/feedback-service.svg'
import connected from '../img/svg/connected-world-rafiki.svg'
import about_home from '../img/svg/pessoas.svg'
import search_home from '../img/svg/LUPA.svg'
import background_home from '../img/svg/background/fundo.svg'
import background_site from '../img/svg/background/Drawing.svg'
import publish_my_work from '../img/svg/MEGAFONE.svg'
import bg_search from '../img/svg/background/fundo.svg'
import bg_search_rotate from '../img/svg/background/fundo_rotate.svg'
import hire_people from '../img/png/down_app/hire.png'
import work_people from '../img/png/down_app/work.png'
import phone_logo from '../img/png/want_work/phone_logo.png'
import scene_1 from '../img/png/scene/scene_1.png'
import scene_2 from '../img/png/scene/scene_3.png'
import scene_double from '../img/png/scene/scene_double.png'
import scene_desktop from '../img/png/scene/scene_desktop.png'
import logo_uf_v from '../img/png/logo/logo_ro_vertical.png'
import logo_uf_h from '../img/png/logo/logo_ro_horizontal.png'
import brasao_ro from '../img/png/logo/brasao_ron.png'

import face_icon from '../img/svg/icons_facebook.svg'
import insta_icon from '../img/svg/icons_instagram.svg'
import code_verification from '../img/codeImg.png'

export const Images = {
    home_page_svg: {
        search_home,
        about_home,
        background_home
    },

    i_want_work: {
        publish_my_work,
        phone_logo
    },

    download_app_peoples: {
        hire_people,
        work_people,
    },

    scenes: {
        scene_1,
        scene_2,
        scene_double,
        scene_desktop
    },

    logo_gov: {
        logo_uf_v,
        logo_uf_h,
        brasao_ro
    },
    code_verification,
    face_icon,
    insta_icon,
    bg_search,
    bg_search_rotate,
    background_site,

    connected,
    case_img,
    resume_not_found,
    menuWorker,
    logo_header,
    feedbackService,
    logo,
    no_notification_found,
    logo_footer: '',
    user_place_holder,
    auth: {
        number_step,
    },

    panel: {
        notification_card,
        profile_card,
        agenda_card,
    },

    gplay_icon,
    service_not_found,

    avatarLogo: 'https://crafty-api-dev.s3.amazonaws.com/public/thumbs/'
};
