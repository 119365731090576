import React from 'react'
import {Col} from "react-bootstrap";
import RateStars from "../../../components/Generic/RateStars";
import {Images} from "../../../helpers/Images";

const ServiceProviderProfile = ({name, avatar, faceLink, instaLink, occupation}) => {
    return (
        <div className={'bg-white'}>
            <div className='d-flex gap-3 p-3'>
                <div>
                    <img className='rounded-circle' style={{width: 128, height: 128, objectFit: 'cover'}} alt='profile image' src={avatar}/>
                </div>
                <Col>
                    <div>
                        <span className='text-dark fs-3' > {name} </span>
                    </div>
                    <div>
                        <span className='text-muted fs-5' > {occupation.name}  </span>
                    </div>
                    <div className='da-flex gap-2'>
                        {occupation.price_avg !== 0 &&<span><strong className='text-success'> {occupation.price_avg <= 2? '$' :(occupation.price_avg <= 3)? '$$' :(occupation.price_avg <= 4)? '$$$': (occupation.price_avg <= 5)&& '$$$$'} </strong>  <strong className='mx-2'>/</strong>  </span> }
                        <Col xs={7} md={6} lg={4} xl={3} className='d-flex gap-2'>
                            <RateStars size={16} disabled defaultValue={occupation.ratings_count}/>
                            <div>
                                ({occupation.ratings_count})
                            </div>
                        </Col>
                    </div>
                    <div className='d-flex mt-2'>
                        {instaLink &&<div style={{marginRight: 15}}>
                            <a className={'gap-1 da-flex text-decoration-none'} target={`_blank`} href={`https://www.instagram.com/${instaLink}`}>
                                <img  style={{height: 20, width: 20}} src={Images.insta_icon}/>@{instaLink}
                            </a>
                        </div>}
                        {faceLink &&<div>
                            <a className={'gap-1 da-flex text-decoration-none'}  target={`_blank`} href={`https://www.instagram.com/${faceLink}`}>
                                <img style={{height: 20, width: 20}} src={Images.face_icon}/>@{faceLink}
                            </a>
                        </div>}
                    </div>
                </Col>
            </div>
            <div className='border-bottom border-1 pt-3'/>
        </div>

    )
}

export default ServiceProviderProfile
