import React, {useEffect, useMemo, useState} from 'react';
import {Route, Switch, useHistory, withRouter} from "react-router-dom";
import DefaultLayout from '../layout/DefaultLayout';
import ServicePage from "../pages/Service/ServicePage";
import {PrivateRoute} from "../helpers/PrivateRoute";
import useAuth from "../hooks/Auth";
import WorkHomePage from "../pages/WantWork/WorkHomePage";
import {useDispatch, useSelector} from "react-redux";
import useLocalStorage from "../hooks/Storage";
import useApi from "../hooks/Api";
import {Button} from "react-bootstrap";
import PrivacyPage from "../pages/TermsOfUse/PrivacyPage";
import PasswordRecovery from "../pages/PasswordRecovery/PasswordRecovery";
import ClientPanelPageRoutes from "./ClientPanelPageRoutes";
import {Env} from "../helpers/Env";
import PanelPage from "./PanelPage";
import {StorageVariables} from "../helpers/StoragesVariables";
import * as Sentry from "@sentry/react";
import HomePage from "../pages/HomePage/HomePage";
import WantToHire from "../pages/SearchWorker/WantToHire";
import {toast} from "react-hot-toast";
import {CgClose} from "react-icons/all";
import {BsFillBellFill} from "react-icons/bs";
import NotificationPage from "../pages/Notification/NotificationPage";
import RemoveAccount from "../pages/RemoveAccount";


const LayoutManager = () => {
    const {isLogged, getUser, store} = useAuth();
    const dispatch = useDispatch();
    let messaging = window.firebase?.messaging?.isSupported()? window.firebase.messaging() : null;
    const profile = useSelector(state => state).authReducer;
    const request = useSelector(state => state).pushNotificationRequestReducer;
    const userCookiesPermission = useLocalStorage(StorageVariables.cookies);
    const [, setButtonCookieClicked] = useState(false);
    const fcmStorage = useLocalStorage(StorageVariables.fcm);
    const api = useApi();
    const envApi = useApi({headers:{...Env.header, 'X-App-Package': Env.application_name}});
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const getEnv = async () => {
        let aux = {}

        envApi.get('user/application', (res) => {
            Object.keys(res).forEach((item) => {
                if (item !== 'additional_configs') {
                    aux[item] = res[item]
                }
            })

            Env.configs = {...res?.additional_configs, ...aux};
            setLoading(false)
        }, () => {
            setLoading(false)
        })
    }

    const configNotifications = () => {
        Notification.requestPermission().then((permission ) => {
            if (permission === 'granted') {
                messaging?.getToken().then(async (currentToken) => {
                    if (currentToken) {
                        if(fcmStorage.getPrimitive() !== currentToken) {
                            api.post('notifications/register', {fcm_token: currentToken, application_name: Env.application_name}, () => {}, () => {})
                        }
                        fcmStorage.setPrimitive(currentToken);
                        messaging?.onMessage((payload) => {
                            console.log(payload);
                            if(payload.data["crafty.fcm_type"] === "chat") {
                                dispatch({type: 'new_chat_notification', data: {chat_id: payload.data["crafty.chat_id"]}});
                            } else {
                                dispatch({type: 'add_notification'});
                                toast(
                                    <div className='flex-grow-1'>
                                        <div className='position-absolute' style={{right:10, top:10}}>
                                            <CgClose onClick={() => toast.dismiss()} style={{cursor:"pointer"}} size={20}/>
                                        </div>
                                        <div className='d-flex'>
                                            <div>
                                                <div>
                                                    <strong style={{fontSize: 16}}> Notificação </strong>
                                                </div>
                                                <div>
                                                    {payload.notification?.body || 'Você recebeu uma nova notificação'}
                                                </div>
                                                <div className='link-primary cursor-pointer'>
                                                    <span onClick={() => {history.push('/notificacoes'); toast.dismiss()}} className='d-inline-block'>Ver notificação</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>, {
                                        position:"top-right",
                                        duration: 8000,
                                        style: {
                                            position:"relative",
                                            maxWidth:400,
                                            height:80,
                                            gap:8,
                                            justifyContent: "start"
                                        },
                                        icon: <div className='ringing-bell'><BsFillBellFill className='faa-ring animated' size={24} style={{marginLeft:8}}/></div>,
                                    }
                                )
                            }
                        });
                        dispatch({type: 'enabled_push', data: true})
                    } else {
                        console.log('No Instance ID token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
            } else {
                console.log('Unable to get permission to notify.');
            }
        });
    };

    const getNotifications = async () => {
        api.get('notifications/count', (res) => {
            dispatch({type: 'init_count', data: res.count});
        }, (e) => {
            console.log(e);
        });
    };


    const handleCookiesAccept  = () => {
        userCookiesPermission.setPrimitive(true);
        setButtonCookieClicked(true);
    };

    const checkDataUser = (userObj) => {
        api.get(`user/${userObj.user_id}/full`, (user) => {
            store(user); // Atualiza agora com oq tem na api
        }, (err)=> {
        })
    }


    useMemo(() => {
        const userObj = getUser();

        if(userObj?.token) {
            store(userObj, true); // Ja inicializa o redux com oq tem no storage
            checkDataUser(userObj);
        }
        if (messaging !== null) {
            try {
                messaging?.usePublicVapidKey("BJfOZ_N3UFBHd3bWbkJyl-FDN1_EytfMm5k-Ham37qNzG4ML2f9A3JLCqPN1Vx1qfda3jqJkL9dCtp2BhIa_gWM");
            } catch (e) {
                console.log(e);
            }
        }
    }, []);

    useEffect(() => {
        if(profile !== null && messaging !== null) {
            configNotifications();
        }
    },[profile, request]);

    useEffect(() => {
        Sentry.addBreadcrumb({
            category: "active_screen",
            message: window.location.pathname,
            level: Sentry.Severity.Info,
        });
        window.gtag&& window.gtag('config', 'G-V5VXHPXVPT', {'page_path': window.location.pathname});
    },[window.location.pathname]);

    useEffect(() => {
        getEnv()
    }, []);

    if (loading) return <></>
    return (
        <Switch>
            <Route>
                <DefaultLayout>
                    <Route exact path={'/'} component={isLogged()? PanelPage : HomePage}/>
                    <PrivateRoute exact path={'/painel'} component={PanelPage}/>
                    <PrivateRoute path={'/painel/cliente'} component={ClientPanelPageRoutes}/>

                    <Route exact path={'/termos'} component={PrivacyPage}/>
                    <Route exact path={'/politica-de-privacidade'} component={PrivacyPage}/>
                    <Route exact path={'/redefinir-senha/:token'} component={PasswordRecovery}/>
                    <Route exact path={'/trabalhar'} component={isLogged()? PanelPage : WorkHomePage}/>

                    <Route exact path={'/quero-contratar'} component={WantToHire}/>
                    <Route exact path={'/prestador-de-servico/:id/:occupation'} component={ServicePage}/>
                    <Route exact path={'/remover-conta'} component={RemoveAccount}/>

                    <PrivateRoute exact path={'/notificacoes'} component={NotificationPage}/>
                </DefaultLayout>
            </Route>

            {!userCookiesPermission.getPrimitive() && <div>
                <span style={{marginRight: '10px', paddingTop: '7px',fontSize: '13px'}}>Nós utilizamos cookies para garantir que forneceremos a melhor experiência em nosso site.</span>
                <div>
                    <Button style={{marginRight: 10}} onClick={() => handleCookiesAccept()}> Aceitar </Button>
                    <Button variant="danger" href={'https://www.google.com.br'}> Eu Discordo </Button>
                </div>
            </div>}
        </Switch>
    )

};

LayoutManager.propTypes = {};

export default withRouter(LayoutManager);
