import React, {useState} from "react";
import {Button, Col, Modal, Spinner} from "react-bootstrap";
import {Env} from "../../../helpers/Env";
import InputHF from "../../../components/Generic/InputHF";
import NumberFormat from "react-number-format";
import LabelWithLibs from "../../../components/Generic/LabelWithLibs";
import { FaCalendarDay, FaIdCard} from "react-icons/fa";
import * as yup from "yup";
import * as md5 from "md5";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Select from "react-select";
import {OptionsSelect} from "../../../helpers/OptionsSelect";
import {customStyles} from "../../../components/Generic/ReactSelect/CustomStyles";
import {BsAt, BsPeopleFill, BsPersonBoundingBox, BsPersonFill} from "react-icons/bs";
import moment from "moment/moment";
import useApi from "../../../hooks/Api";
import useAuth from "../../../hooks/Auth";
import {toast} from "react-hot-toast";
import {useHistory} from "react-router-dom";
import {AiFillPhone} from "react-icons/all";

const RegisterModal = ({openModal, closeModal, changeStepModal}) => {

    const [loading, setLoading] = useState(false);

    const api = useApi();
    const {login} = useAuth();
    const history = useHistory();

    const registerSchema = yup.object().shape({
        phone_number: yup.string().required('Informe seu número de telefone').min(11, 'Insira um número válido'),

        first_name: yup.string().required('O nome é obrigatório'),
        last_name: yup.string().required('O sobrenome é obrigatório'),
        cpf: yup.string().required('É necessário informar seu CPF').test('cpf', 'Insira um CPF válido', (value) => {
            return value && value.length === 11
        }),
        email: yup.string().email('Insira um e-mail válido').required('O email é obrigatório'),
        birthday: yup.string().required('A data de nascimento é obrigatória'),
        gender: yup.string().required('Selecione seu gênero'),
        password: yup.string().required('Informe uma senha').min(8, 'A senha deve conter no mínimo 8 caracteres'),
        confirm_password: yup.string().oneOf([yup.ref('password'), null], 'As senhas não coincidem')
    });

    const {register, handleSubmit, setValue, getValues, trigger, formState: { errors },} = useForm({
        resolver: yupResolver(registerSchema),
    });

    const onSubmit = (data) => {
        setLoading(true)
        let sendApi = {
            ...data,
            cellphone_number: `+55${data.phone_number}`,
            fcm_token: 'undefinedToken',
            password: md5(data.password),
            confirm_password: md5(data.confirm_password),
            application_name: Env.application_name,

        }
        delete sendApi.phone_number

        api.post('register/worker', sendApi, (res) => {
            window.gtag&& window.gtag('event', 'registerSuccess');
            history.push('/')
            setLoading(false)
            doAfterRegister(res);

        }, (e) => {
            window.gtag&& window.gtag('event', 'registerFailure');
            toast.error(e.message || 'Não foi possível registar, tente novamente mais tarde');
            setLoading(false)
        });
    }

    const doAfterRegister = (res) => {
        closeModal()
        toast.success('Seja bem-vindo(a)!', {duration:4000, position:"top-right"})
        login({
            token: res.api_key,
            user_id: res.user_id,
            email: res.profile.email,
            nickname: res.profile.nickname,
            mobile_verified: res.profile.mobile_verified,
            avatar: res.profile.profile_picture?.thumbnail,
            addresses: res.addresses,
            name: (res.profile.first_name + ' ' + res.profile.last_name),
            gender: res.profile.gender,
            cpf: res.profile.cpf,
            phone: res.profile.cellphone_number,
            birthday: res.profile.birthday,
        });
        history.push('/quero-contratar')
    }

    const onError = () => {return 0}

    return (
        <Modal show={openModal} onHide={closeModal} size={"lg"} scrollable>

            <Modal.Body className='p-0'>
                <Modal.Header className='border-0 p-3 position-absolute' style={{right:0}} closeButton/>
                <div className='d-flex flex-column flex-lg-row'>
                    <Col md={12} lg={4} className='bg-primary'>
                        <div className='de-flex justify-content-between flex-column fs-3 p-3 h-100 text-white'>
                            <div className='fw-bold text-center'>
                                Criando sua conta!
                            </div>
                            <div className='da-flex flex-column gap-2'>
                                <div className='text-center'>
                                    Complete as informações
                                </div>
                            </div>

                            <div className='fs-7'>
                                Insira as informações necessárias ao lado para completar seu cadastro
                            </div>
                        </div>
                    </Col>

                    <Col md={12} lg={8} className='p-3 d-flex flex-column gap-2 mt-lg-4'>
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className='d-flex flex-wrap'>
                                <Col xs={12} lg={6} className='p-2'>
                                    <InputHF label='Nome'
                                             iconLabel={<BsPersonFill size={16}/>}
                                             key='first_name'
                                             register={register('first_name')}
                                             error={errors?.first_name?.message}
                                    />
                                </Col>

                                <Col xs={12} lg={6} className='p-2'>
                                     <InputHF label='Sobrenome'
                                              iconLabel={<BsPeopleFill size={16}/>}
                                              key='last_name'
                                              register={register('last_name')}
                                              error={errors?.last_name?.message}
                                     />
                                </Col>

                                <Col xs={12} className='p-2'>
                                    <InputHF label='Email'
                                             iconLabel={<BsAt size={16}/>}
                                             key='email'
                                             register={register('email')}
                                             error={errors?.email?.message}
                                    />
                                </Col>

                                <Col xs={12} lg={6} className='p-2'>
                                    <div className={` form-floating mb-2`}>
                                        <NumberFormat className={`${errors?.phone_number?.message ? 'form-control is-invalid' : 'form-control'}`}
                                                      value={getValues()?.phone_number}
                                                      {...register('phone_number')}
                                                      placeholder='Telefone'
                                                      onValueChange={async(e) => {
                                                          setValue('phone_number', e.value)
                                                          e.value.length === 11 && await trigger('phone_number')
                                                      }}
                                                      format={'(##) #####-####'}
                                                      mask="_"
                                        />
                                        <LabelWithLibs label='Informe seu número' labelIcon={<AiFillPhone size={16}/>} className={`${errors?.phone_number?.message ? 'invalid-feedback' : ''}`}/>
                                        {errors?.phone_number?.message &&
                                            <div className={`${errors?.phone_number?.message ? 'invalid-feedback' : ''}`}>
                                                {errors?.phone_number?.message}
                                            </div>
                                        }
                                    </div>
                                </Col>


                                <Col xs={12} lg={6} className='p-2'>
                                    <div className={`form-floating`}>
                                        <NumberFormat className={`${errors?.cpf?.message ? 'form-control is-invalid' : 'form-control'}`}
                                                      value={getValues()?.cpf}
                                                      {...register('cpf')}
                                                      onValueChange={(e) => setValue('cpf', e.value)}
                                                      placeholder='CPF'
                                                      format={'###.###.###-##'}
                                                      mask="_"
                                        />
                                        <LabelWithLibs label='CPF' labelIcon={<FaIdCard size={16}/>} className={`${errors?.cpf?.message ? 'invalid-feedback' : ''}`}/>
                                        {errors?.cpf?.message &&
                                            <div className={`${errors?.cpf?.message ? 'invalid-feedback' : ''}`}>
                                                {errors?.cpf?.message}
                                            </div>
                                        }
                                    </div>
                                </Col>

                                <Col xs={12} lg={6} className='p-2'>
                                    <div className='form-floating'>
                                        <NumberFormat className={`${errors?.birthday ? 'form-control is-invalid' : 'form-control'}`}
                                                      value={moment(getValues().birthday, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                                      {...register('birthday')}
                                                      onValueChange={(e) => {
                                                          if (e.formattedValue === moment(e.formattedValue, 'DD/MM/YYYY').format('DD/MM/YYYY')) {
                                                              setValue('birthday', moment(e.formattedValue, 'DD/MM/YYYY').format('YYYY-MM-DD'))
                                                          }
                                                      }}
                                                      placeholder='Data de nascimento'
                                                      format={'##/##/####'}
                                                      mask="_"
                                        />
                                        <LabelWithLibs className={`${errors?.birthday?.message ? 'invalid-feedback' : ''}`} labelIcon={<FaCalendarDay size={16}/>} label='Data de nascimento'/>
                                        <div className={`${errors?.birthday?.message ? 'invalid-feedback' : ''}`}>
                                            {errors?.birthday?.message}
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} lg={6} className='p-2 position-relative'>
                                    <Select placeholder='Gênero'
                                            options={OptionsSelect.genders}
                                            {...register('gender')}
                                            onChange={async(e) => {
                                                setValue('gender', e.value)
                                                await trigger('gender')
                                            }}
                                            isSearchable={false}
                                            styles={errors?.gender?.message ? customStyles.error : customStyles.noSearchable }
                                    />
                                    <LabelWithLibs className={`label-with-select ${errors?.gender?.message ? 'error-label-select ' : ''}` }
                                                   label='Gênero' labelIcon={<BsPersonBoundingBox size={16}/>}
                                    />
                                    <div className={`${errors?.gender?.message ? 'd-block invalid-feedback' : ''}`}>
                                        {errors?.gender?.message}
                                    </div>
                                </Col>

                                 <Col xs={12} lg={6} className='p-2'>
                                     <InputHF label='Senha'
                                              isPassword
                                              register={register('password')}
                                              key='password'
                                              error={errors?.password?.message}
                                     />
                                 </Col>

                                <Col xs={12} lg={6} className='p-2'>
                                    <InputHF label='Confirme a senha'
                                             isPassword
                                             register={register('confirm_password')}
                                             key='confirm_password'
                                             error={errors?.confirm_password?.message}
                                    />
                                 </Col>

                            </div>
                            <div className='px-2'>
                                <span>Ao clicar em <strong>registrar</strong> você concorda com nossos </span>
                                <a target={'_blank'} rel='noreferrer' href={window.location.origin + '/termos'}>termos e políticas de privacidade</a>
                            </div>
                            <div className='d-flex flex-wrap-reverse gap-2 gap-md-0 p-2'>
                                <Col xs={12} md={4} className='pe-md-2'>
                                    <Button type='button' variant='outline-dark' className='w-100' onClick={() => changeStepModal('login')}>
                                        Voltar
                                    </Button>
                                </Col>

                                <Col xs={12} md={8}>
                                    <Button type='submit' className='w-100' disabled={loading}>
                                        <span className='de-flex gap-2'>{loading ? <><Spinner animation='border' size='sm'/>Registrando... </> : <>Registrar</>}</span>
                                    </Button>
                                </Col>
                            </div>
                        </form>
                    </Col>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default RegisterModal
