import React, {useState} from 'react'
import PropTypes from 'prop-types';

import {BsStarFill} from "react-icons/bs";

const colors = {
    yellow: '#FADB13',
    grey: '#e8e8e8'
}

const RateStars = ({count, defaultValue, disabled, size, onChange}) => {
    const [currentValue, setCurrentValue] = useState(defaultValue ? defaultValue :0)
    const [hoverValue, setHoverValue] = useState(undefined)
    const stars = Array(count ? count : 5).fill(0)

    const handleClick = (value) => {
        setCurrentValue(value)
        onChange && onChange(value)
    }

    const handleMouseOver = (value) => {
        setHoverValue(value)
    }

    const handleMouseLeave = () => {
        setHoverValue(undefined)
    }
    return (
        <>
            <div className='rate-stars w-100'>
                {stars.map((_, index) => (
                    <div className={disabled ? '' : 'scale'}>
                        <BsStarFill color={(hoverValue || currentValue) > index ? colors.yellow : colors.grey}
                                    size={size ? size : 20}
                                    key={index}
                                    style={{cursor: disabled ? "default" : "pointer"}}
                                    onClick={() => disabled ? null : handleClick(index + 1)}
                                    onMouseOver={()=> disabled ? null : handleMouseOver(index + 1)}
                                    onMouseLeave={disabled ? null : handleMouseLeave}
                        />
                    </div>

                ))}
            </div>
        </>
    )
}

RateStars.propTypes = {
    count: PropTypes.number,
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool,
    size: PropTypes.number,
    onChange: PropTypes.func
}

export default RateStars