import React, {useState} from 'react';
import PropTypes from 'prop-types';
import useApi from "../../../hooks/Api";
import {Images} from "../../../helpers/Images";
import {Button, Col, Modal} from "react-bootstrap";
import {FaLocationArrow} from "react-icons/all";
import RateStars from "../../../components/Generic/RateStars";

const RateOrderModal = props => {
    const [message, setMessage] = useState("");
    const [rating, setRating] = useState(1);
    const api = useApi({navigation: props.navigation});
    const [step, setStep] = useState('initial');

    const handleSubmit = async () => {
        if(rating === 0) {
            message.error('Avalie com uma ou cinco estrelas para continuar');
        } else {
            setStep('loading');
            api.post(`worker-contact/${props.profile?.id}/review`, {review: message === ""? null : message, rating: rating, price: 1 }, () => {
                setStep('sent');
            }, (e) => {
                message.error(e.message || 'Não foi realizar esta ação, tente novamente');
                setStep('initial');
                console.log(e);
            });
        }
    };

    const handleNoRate = () => {
        // setStep('loading');
        api.post(`worker-contact/${props.profile?.id}/dismiss-review`, null, () => {
            props.handleAfterFeedback();
        }, (e) => {
            message.error(e.message || 'Não foi realizar esta ação, tente novamente');
            setStep('initial');
            console.log(e);
        });
    }

    return (
        <Modal show={props.visible} onHide={() => {props.hide(false);}}>
            <Modal.Header className='border-0 p-0 pt-3 pe-3' closeButton/>

            <Modal.Body className='text-center'>
                {step === `initial` ?
                    <>
                        <div className='d-flex flex-column gap-2'>
                            <div className='da-flex gap-2'>
                                <div>
                                    <img alt='profile image' className='rounded-circle' style={{height: 96, objectFit: 'cover', width: 96}}
                                         src={props.profile.worker.profile_picture || Images.user_place_holder}
                                    />
                                </div>

                                <div className='text-start'>
                                    <div className='fw-bold text-dark fs-5'>
                                        {props.profile.worker?.name}
                                    </div>
                                    <div className='fs-6 text-muted'>
                                        {props.profile.occupation?.name}
                                    </div>
                                </div>
                            </div>

                            <div className='border-bottom my-3'/>

                            <div className='d-flex flex-column gap-3'>
                                <div className='da-flex flex-column gap-2 '>
                                    <span className='fs-6'> Nota do atendimento </span>
                                    <Col xs={6}>
                                        <RateStars size={28} defaultValue={rating} onChange={(e) => setRating(e)}/>
                                    </Col>
                                </div>

                                <div className='fs-6'>
                                    <div className='mb-2'>Escreva aqui sua avaliação, caso ache necessária</div>
                                    <div>
                                        <textarea style={{resize:"none"}} rows={4} value={message} onChange={(e) => setMessage(e.target.value)} maxLength={300} className='input-text-area'/>
                                    </div>

                                </div>
                            </div>

                            <div className='text-center'>
                                <Button className='mb-3' variant='success' onClick={() => handleSubmit()} key="submit" >
                                    <span className='d-flex align-items-center gap-2'>
                                        Enviar Avaliação <FaLocationArrow size={16}/>
                                    </span>
                                </Button>

                                <div className='text-muted cursor-pointer link-primary' onClick={() => handleNoRate()}>
                                    Não quero avaliar
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div>
                            <div style={{marginBottom: 20, fontSize: 25}}>
                                <span> {step === `loading` ? 'Enviando' : 'Avaliação Enviada!'} </span>
                            </div>
                            <div className='modal-loader'>
                                <div className={step === `loading` ? "circle-loader" : "circle-loader load-complete"}>
                                    <div style={{display: step === `sent` ? 'block' : 'none'}} className="checkmark draw"/>
                                </div>
                                <div>
                                    <Button style={{zIndex: 2000}} onClick={() => {
                                        props.handleAfterFeedback();
                                        props.hide(false);
                                    }}> Ok </Button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>

    );
};

RateOrderModal.propTypes = {
    itemToFeedback: PropTypes.any,
    hide: PropTypes.any,
    setShowRateModal: PropTypes.any
};

export default RateOrderModal;
