import React, {useEffect, useRef} from 'react';
import {Button, Col} from "react-bootstrap";
import useApi from "../../hooks/Api";
import {useHistory} from "react-router-dom";

import {Images} from "../../helpers/Images";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import InputHF from "../../components/Generic/InputHF";
import {toast} from "react-hot-toast";

const PasswordRecovery = (props) => {

    const schema = yup.object({
        password: yup.string().required('Informe sua nova senha').min(8, 'A senha deve conter no mínimo 8 caracteres'),
        passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'As senhas não coincidem')
    });

    const {register, handleSubmit, formState: { errors },} = useForm({
        resolver: yupResolver(schema),
    });

    const history = useHistory();
    const token = useRef(props.match.params.token);
    const api = useApi();
    let md5 = require('md5');
    
    const handleChangePassword = (data) => {
        let sendData = {password: md5(data.password), token: token.current} ;
        api.post('access/change-password',sendData ,() => {
            toast.success('Senha redefinida com sucesso!');
            history.push('/');
        }, (e) => {
            toast.error(e.message || 'Ocorreu um erro tente novamente');
        });
    };

    const onError = () => {return 0}

    useEffect(() => {
        if(!props.match.params.token) {
            toast.error('Token invalido!');
            history.push('/');
        }
    },[]);

    return (
        <>
            <form className='d-flex justify-content-center' onSubmit={handleSubmit(handleChangePassword, onError)}>
                <Col xs={11} md={7} lg={5} xl={4} className='p-3 shadow-sm bg-white rounded-3'>
                    <div className='logo' style={{marginBottom: 20, textAlign: 'center'}}>
                        <img style={{maxWidth: 288}} src={Images.logo} alt='logo'/>
                    </div>
                    <div className='text-center'>
                        <h4>Redefinição de senha</h4>
                    </div>
                    <div className='mb-2'>
                        <InputHF label='Nova senha'
                                 isPassword
                                 register={register('password')}
                                 key='password'
                                 error={errors?.password?.message}
                        />
                    </div>

                    <div className='mb-2'>
                        <InputHF label='Confirme a nova senha'
                                 isPassword
                                 register={register('passwordConfirmation')}
                                 key='passwordConfirmation'
                                 error={errors?.passwordConfirmation?.message}
                        />
                    </div>

                    <div>
                        <Button variant='success' type='submit' className='text-white w-100' >
                            Trocar senha
                        </Button>
                    </div>
                </Col>
            </form>
        </>
        )
};
export default PasswordRecovery;
