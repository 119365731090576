import React from 'react';
import {Images} from "../../helpers/Images";
import {useHistory} from "react-router-dom";
import {Col, Container} from "react-bootstrap";
import LogoUF from "../../components/Generic/LogoUF";

const ClientPanelComponent = () => {
    const history = useHistory();


    return (
        <Container className='d-flex flex-wrap py-5'>
            <Col xs={12} md={12} className='p-2' onClick={() => history.push('/painel/cliente/contatos')}>
                <div className='card-panel-client d-flex border-bottom-primary-3 p-4 bg-white rounded-4'>
                     <Col xs={8}>
                         <h4 className='text-primary'>Ver Lista de Contatos</h4>

                         <span className='text-dark'>Veja aqui a lista de contatos dos prestadores de serviço que solicitou, e não se esqueça de avaliar! </span>
                     </Col>
                    <Col md={4}>
                        <img className='w-100' src={Images.panel.agenda_card} alt='view list'/>
                    </Col>
                </div>
            </Col>

            <Col xs={12} md={6} className='p-2' onClick={() => {history.push('/painel/cliente/perfil')}}>
                <div className='card-panel-client d-flex border-bottom-secondary-3 p-4 bg-white rounded-4'>
                    <Col xs={8}>
                        <h4 className='text-secondary'>Visualize seu perfil</h4>

                        <span className='text-dark'>
                        Edite as informações do seu perfil. Nos conte um pouco mais sobre você.
                     </span>
                    </Col>
                    <Col md={4}>
                        <img className='w-100' src={Images.panel.profile_card} alt='view list'/>
                    </Col>
                </div>
            </Col>

            <Col xs={12} md={6} className='p-2' onClick={() => {history.push('/painel/cliente/notificacoes')}}>
                <div className='card-panel-client d-flex border-bottom-sucess-3 p-4 bg-white rounded-4'>
                    <Col xs={8}>
                        <h4 className='text-success'>Notificações</h4>

                        <span className='text-dark'>
                         Manteremos você informado de tudo que for de seu interesse aqui!
                     </span>
                    </Col>
                    <Col md={4}>
                        <img className='w-100' src={Images.panel.notification_card} alt='view list'/>
                    </Col>
                </div>
            </Col>
        </Container>
    )
};
export default ClientPanelComponent;
