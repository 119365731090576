import React, {useEffect, useRef, useState} from 'react'
import useApi from "../../hooks/Api";
import {Button, Col, Container, Row} from "react-bootstrap";
import moment from "moment/moment";
import {BsEye, BsFillBellFill} from "react-icons/bs";
import NotFound from "../../components/Generic/NotFound";
import {Images} from "../../helpers/Images";
import Loading from "../../components/Generic/Loading";
import InfiniteScroll from "../../components/Generic/InfiniteScroll";
import {toast} from "react-hot-toast";
import {useDispatch} from "react-redux";
import ModalViewNotification from "./Modal/ModalViewNotification";


const NotificationPage = () => {
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState(null);
    const [viewNotification, setViewNotification] = useState(false);

    const paginate = useRef({page: 0});
    const api = useApi();
    const dispatch = useDispatch();

    const getData = () => {
        api.get(`notifications/list?page=${paginate.current.page + 1}&order=desc`, (res) => {
            paginate.current = {nextPage: res.next_page, page: res.page};
            !notifications ? setNotifications(res.notifications) : setNotifications(notifications?.concat(res?.notifications))
            setLoading(false);
        }, (e) => {
            toast.error('Ops, não foi possível recuperar suas notificações, tente novamente');
            setLoading(false);
        });
    }

    const handleDelete = (id, index) => {
        toast.loading('Removendo notificação', {id: 'clipboard'})

        api.del(`notifications/${id}`, null,(res) => {
            let aux = [...notifications]
            aux.splice(index, 1)
            setNotifications(aux)

            toast.success(`Notificação removida`,{id: 'clipboard'})

            dispatch({type: 'remove_notification'});
        }, (e) => {
            toast.error(e.message || 'Ops, não foi possível Remover esta notificação!',{id: 'clipboard'})
        });
    }

    const loadMore = () => {
      if (paginate.current.nextPage) {
          getData()
      }
    }

    useEffect(() => {
        getData()
    },[])

    return (
        <Container className='py-5'>
            <div className='d-flex align-items-center gap-2 fs-5 mb-4'>
                <BsFillBellFill/>
                <span> Suas notificações </span>
            </div>

            <Row className='g-3 notification-page'>
                {loading ?
                    <Loading/> :
                    <>
                        {notifications?.map((item, index) => (
                           <Col xs={12} md={6} lg={4}>
                               <div key={index} className='d-flex flex-column gx-2 h-100 justify-content-between gap-3 rounded-3 bg-white shadow p-4 position-relative'>

                                   <h5 className='text-center mt-3 mb-0 fw-bold text'>
                                       Notificação
                                   </h5>

                                   {item.title &&
                                       <h6 className='text-center  fw-bolder text mb-0'>
                                            {item.title}
                                       </h6>
                                   }

                                   {item.image &&
                                       <div>
                                           <img src={item.image} className='w-100' alt="image"/>
                                       </div>
                                   }

                                   <div className='limit-rows clamp-3 text-justify'>
                                       {item.message}
                                   </div>
                                   <div className='d-flex justify-content-between gap-3'>
                                       {(item.link)  &&
                                           <Button className='w-100'
                                                   variant={"success"}
                                                   onClick={() => setViewNotification(item) }>
                                                <span className='text-white de-flex gap-2'>
                                                    <BsEye size={20}/> Ver mais
                                                </span>
                                           </Button>
                                       }
                                       <button className='button-notification-remove' type={"button"}
                                               onClick={() => handleDelete(item.notification_id, index)}>
                                           Entendi
                                       </button>
                                   </div>
                                   <div className='text-muted' style={{position: 'absolute', right: 15, top: 15}}>
                                       {moment.utc(item.created_at).local().fromNow()}
                                   </div>
                               </div>
                               {(index === notifications?.length - 3 && paginate.current.nextPage) &&
                                   <>
                                       <InfiniteScroll fetchMore={()=> loadMore()}/>
                                   </>
                               }
                           </Col>
                       ))}

                        {(!loading && paginate.current.nextPage) &&
                            <Loading/>
                        }
                    </>
                }

                {notifications?.length === 0 &&
                    <div>
                        <NotFound title={<span style={{fontWeight: 'bold'}}> Você não possui nenhuma notificação! </span>}
                                  subTitle={'Aqui aparece o status de todos os seus agendamentos com prestadores de serviços, além de promoções e notícias!'}
                                  img={Images.no_notification_found}
                        />
                    </div>

                }
            </Row>
            <ModalViewNotification viewNotification={viewNotification} close={() => setViewNotification(false)}/>
        </Container>
    )
}

export default NotificationPage