import React, {useEffect, useRef, useState} from "react";
import SearchWorkerComponent from "./Components/SearchWorkerComponent";
import {Container} from "react-bootstrap";
import useApi from "../../hooks/Api";
import useLocalStorage from "../../hooks/Storage";
import CardListComponent from "./Components/CardListComponent";
import {toast} from "react-hot-toast";
import Loading from "../../components/Generic/Loading";
import {Images} from "../../helpers/Images";
import NotFound from "../../components/Generic/NotFound";
import LogoUF from "../../components/Generic/LogoUF";

const WantToHire = ({}) => {

    const [listProvidersResult, setListProvidersResult] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false)
    const [options, setOptions] = useState()

    const paginate = useRef({page: 1});
    const api = useApi({customReturn: true, loadingControl: false});

    const lastWorkerSearch = useLocalStorage('lastWorkerSearch');

    const queryStringToObject = Object.fromEntries(new URLSearchParams(window.location.search))

    const background = {
        backgroundImage: `url(${Images.bg_search_rotate})`,
        backgroundPosition:"top",
        backgroundSize:"cover",
        marginTop: -125
    }

    const getData = (more) => {
        more ? setLoadingMore(true) : setLoading(true);
        let qsToObj = Object.fromEntries(new URLSearchParams(window.location.search))
        let filters = {
            latitude: Number(qsToObj.latitude),
            longitude: Number(qsToObj.longitude),
            occupation_id: qsToObj.occupation_id,
            worker: qsToObj.worker
        }
        filters.page = paginate.current.page;

        api.post('search', filters, (res) => {
            paginate.current = {nextPage: res.next_page, page: res.page};
            let aux;
            if (more) {
                aux = listProvidersResult.concat(res.workers)
            } else {
                aux = res.workers;
            }
            more ? setLoadingMore(false) : setLoading(false);
            setListProvidersResult(aux);
        },(e) => {
            console.log(e)
            more ? setLoadingMore(false) : setLoading(false);
        });
    }

    const getServices = () => {
        let aux = []
        api.get('search/occupations', (res) => {

            res.forEach((item) => {
                item.occupations.map((subItem) => {
                    return (
                        aux.push({
                            value: subItem.id,
                            label: subItem.name,
                        })
                    )
                })
            });
            setOptions(aux);
        }, (e) => {
            console.log(e)
        });
    };


    const infiniteScroll = () => {
        if (paginate.current.nextPage === true) {
            paginate.current.page = paginate.current.page +1
            getData(true)
        }
    }

    const handleSearch = () => {
        const checkParamsUrl = new URLSearchParams(window.location.search)

        if (checkParamsUrl.has(`occupation_id`) && checkParamsUrl.has(`latitude`)) {
            let qsToObj = Object.fromEntries(new URLSearchParams(window.location.search))
            lastWorkerSearch.setObject(qsToObj)
            getData()
        }

        if (!checkParamsUrl.has(`occupation_id`)) {
            toast.error(`Necessário informar o tipo de serviço!`,{ id: 'clipboard' })
        } else if (!checkParamsUrl.has(`latitude`)) {
            toast.error(`Necessário informar o endereço!`,{ id: 'clipboard' })
        }
    };

    useEffect(() => {
        getServices()
    }, []);

    return (
        <div className='d-flex flex-column h-100'>
            <div style={background} className='py-4'>
                <Container className='pb-0 pb-md-4' style={{padding:'130px 10px'}}>
                    <SearchWorkerComponent services={options}
                                           lastWorkerSearch={lastWorkerSearch}
                                           filtersQuery={queryStringToObject}
                                           handleSearch={() => handleSearch()}
                    />
                </Container>
            </div>


            {loading ?
                <div className='py-5 m-auto'>
                    <Loading/>
                </div>

                :
                <Container>

                    <CardListComponent occupation_id={queryStringToObject.occupation_id}
                                       loading={loading}
                                       servicesList={listProvidersResult}
                                       fetchMore={()=> infiniteScroll()}
                    />

                    {loadingMore && <Loading/>}

                    {(listProvidersResult?.length === 0 && !loading) &&
                        <div className='d-flex justify-content-center py-5'>
                            <NotFound  title={'Ainda não há prestadores de serviço para essa pesquisa'}
                                       subTitle={'Verifique novamente mais tarde se algum prestador já se cadastrou'}
                                       img={Images.service_not_found}
                            />
                        </div>
                    }

                    {(!paginate.current.nextPage && !loading && listProvidersResult?.length > 0) && (
                        <>
                            <div className='fs-6 my-3 py-3 text-center'>
                                Não há mais resultados para essa busca.
                            </div>
                        </>
                    )}
                </Container>

            }
        </div>
    )
}

export default WantToHire
