import React from "react";
import {Button, Modal} from "react-bootstrap";
import InputHF from "../../../components/Generic/InputHF";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import * as md5 from "md5";
import {toast} from "react-hot-toast";
import useApi from "../../../hooks/Api";

const ChangePasswordModal = ({openModal, closeModal, onError}) => {
    const api = useApi();

    const schema = yup.object({
        old_password: yup.string().required('Necessário informar sua antiga senha'),
        password: yup.string().required('Informe sua nova senha').min(8, 'A senha deve conter no mínimo 8 caracteres'),
        passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'As senhas não coincidem')
    });

    const {register, handleSubmit, reset, formState: { errors },} = useForm({
        resolver: yupResolver(schema),
    });

    const changePassword = (data) => {
        let sendData = {old_password: md5(data.old_password), password: md5(data.password)} ;

        api.post('user/change-password', sendData, () => {
            toast.success('Senha alterada com sucesso!');
            closeModal();
            reset()
        }, (e) => {
            toast.error(e.message || 'Ops!, não foi possível salvar seus dados', {id: 'clipboard'})
        });

    };

    return (
        <Modal show={openModal} onHide={closeModal}>
            <Modal.Header className='border-0 p-0 pt-3 pe-3' closeButton/>
            <Modal.Body>
                <form onSubmit={handleSubmit(changePassword, onError)} className='d-flex flex-column gap-3'>
                    <div className='fs-4 text-center fw-bold'>
                         Você está prestes a alterar sua senha!
                    </div>

                    <InputHF label='Senha atual'
                             isPassword
                             register={register('old_password')}
                             key='old_password'
                             error={errors?.old_password?.message}
                    />

                    <InputHF label='Nova senha'
                             isPassword
                             register={register('password')}
                             key='password'
                             error={errors?.password?.message}
                    />

                    <InputHF label='Confirme a nova senha'
                             isPassword
                             register={register('passwordConfirmation')}
                             key='passwordConfirmation'
                             error={errors?.passwordConfirmation?.message}
                    />

                    <Button variant='success' type='submit' className='text-white'>
                        Trocar senha
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ChangePasswordModal