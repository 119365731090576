import React from 'react'
import {Button, Container, Nav, Navbar} from "react-bootstrap";
import {Env} from "../../helpers/Env";
import {FaBriefcase, FaHome, FaSearch} from "react-icons/fa";
import {MdDashboard} from "react-icons/md";
import {BsFillBellFill} from "react-icons/bs";
import {FiLogOut} from "react-icons/fi";
import {Images} from "../../helpers/Images";

const NavbarTop = ({isLogged, history, dispatch, handleStepModal, logoutWithoutApi}) => {
    let path = window.location.pathname;

    return (
        <>
            <Navbar className='gov py-3 bg-white'>
                <div className=' gap-2 flex-grow-1 position-relative'>
                    <div className='d-flex flex-column'>
                        <div style={{borderTop:'1px solid #ffb900', height:3, width:'100%'}}/>

                        <div className='first-header da-flex'>
                            <div style={{left:35}} className='position-absolute bg-white px-1'>
                                <img src={Images.logo_gov.brasao_ro} height={70} alt="brasão RO"/>
                            </div>
                            <div style={{marginLeft:110}} className='text-white py-2 fw-lighter'>Governo do <span className='fw-bold'>Estado de Rondônia</span></div>
                        </div>

                        <div style={{borderTop:'6px solid #388DE2', height:2, width:'100%'}}/>

                    </div>

                </div>
            </Navbar>
            <Navbar className={`${(path === '/' || path === '/trabalhar' || path === '/quero-contratar') ?
                'bg-opacity-4' : 'bg-white'} p-0`} expand='lg'>
                <Container className='header'>
                    <>
                        <div className='flex-1 cursor-pointer' onClick={() => isLogged () ? history.push('/painel/cliente') : history.push('/')}>
                            <img className={`${(path === '/' || path === '/trabalhar' || path === '/quero-contratar') ?
                                'logo-white' : ''}`}
                                 src={Env.application_logo} height={80}  alt='logo'/>
                        </div>
                    </>

                    {/*HEADER SUPERIOR*/}
                    <Navbar.Collapse>
                        <Nav className={`${(path === '/' || path === '/trabalhar') ? 'justify-content-start ' : 'justify-content-center'} flex-1 gap-4 `}>
                            <button className={`itens-menu ${(path === '/' || path === '/trabalhar' || path === '/quero-contratar') ? 'without-bg' : ''} gap-2 ${path === '/quero-contratar' ? 'active-success' : ''}`}
                                    disabled={path === '/quero-contratar'}
                                    onClick={() => {history.push('/quero-contratar')}}>

                                <FaSearch/> <span> Buscar</span>
                            </button>

                            <button className=
                                        {`itens-menu gap-2 
                                        ${(path === '/' || path === '/trabalhar' || path === '/quero-contratar') ? 'without-bg' : ''}
                                        ${(path === '/') ? 'active-success' : path === '/painel/cliente' ? 'active-blue' : ''}
                                    `}
                                    onClick={() =>{isLogged () ? history.push('/painel/cliente') : history.push('/');}}>

                                {isLogged() ?
                                    <>
                                        <MdDashboard/> Painel
                                    </> :
                                    <>
                                        <FaHome/> Home
                                    </>
                                }
                            </button>

                            {!isLogged() &&
                                <div className=
                                         {`itens-menu gap-2
                                         ${(path === '/' || path === '/trabalhar' || path === '/quero-contratar') ? 'without-bg' : ''} 
                                         ${(path === '/trabalhar') ? 'active-success' : ''}
                                     `}
                                     onClick={() => {history.push('/trabalhar')}}>
                                    <FaBriefcase/> <span>Divulgue seu serviço</span>
                                </div>
                            }

                            {isLogged() &&
                                <div className=
                                         {`itens-menu gap-2 
                                        ${path === '/quero-contratar' ? 'without-bg' : ''}
                                        ${path === '/painel/cliente/notificacoes' ? 'active-blue' : ''}
                                     `}
                                     onClick={() => {history.push('/painel/cliente/notificacoes')}}>
                                    <BsFillBellFill/> Notificações
                                </div>
                            }
                        </Nav>
                    </Navbar.Collapse>

                    {!isLogged() ?
                        <div  className='d-flex flex-1 align-items-center justify-content-end gap-2 col-5'>
                            <Button style={{fontSize:13}} variant={path === '/quero-contratar' ? 'close-white' : 'primary'} className='p-2 box-shadow-none' onClick={() => handleStepModal('login')}>Entrar</Button>
                            <Button style={{fontSize:13}} className='p-2 box-shadow-none text-decoration-none text-primary' variant='light' onClick={() => handleStepModal('register')}>Cadastrar-se</Button>
                        </div>
                        :
                        <div className='d-flex flex-1 align-items-center justify-content-end gap-2 col-5'>
                            {isLogged() &&
                                <div className={`itens-menu gap-2 ${path === '/quero-contratar' ? 'without-bg' : ''}`}  onClick={() => {logoutWithoutApi(); dispatch({type: 'logout'});}}>
                                    <FiLogOut/> Sair
                                </div>
                            }
                        </div>
                    }
                </Container>
            </Navbar>
        </>

    )
}

export default NavbarTop
