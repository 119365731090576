import React, {useEffect, useRef, useState} from 'react';
import ServiceProviderMain from "./Components/ServiceProviderMain";
import ServiceProviderExtras from "./Components/ServiceProviderExtras";
import useApi from "../../hooks/Api";
import {Images} from "../../helpers/Images";
import {Env} from "../../helpers/Env";
import {Col, Container} from "react-bootstrap";
import Loading from "../../components/Generic/Loading";
import {toast} from "react-hot-toast";
import ServiceProviderProfile from "./Components/ServiceProviderProfile";


const ServicePage = (props) => {
    const user = useRef();
    const reviewsPaginate = useRef({page: 1});
    const [reviews,setReviews] = useState([]);
    const [reviewLoading, setReviewLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const api = useApi({customReturn: true, loadingControl: false ,debounceTimer: 0});
    const occupation = useRef({});
    const [phoneNumber, setPhoneNumber] = useState(undefined);
    const [requestContactLoading, setRequestContactLoading] = useState(false);

    const getData = async (id, cb) => {
        setLoading(true);
        api.get('user/'+id+'/full', (res) => {
            user.current = res;
            for(let i = 0; i < res.occupations.length; i++) {
                if (res.occupations[i].occupation_id.toString() === props.match.params.occupation) {
                    occupation.current = res.occupations[i];
                }
            }
            if(res.profile.requested_contact_id) {
                getUserContact(res.profile.requested_contact_id);
            }
            setLoading(false);
        },(e) => {
            setLoading(false);
            toast.error('Ops, não foi possível recuperar os dados do profissional, tente novamente mais tarde.')
        });
    };

    const getReviews = async () => {
        setReviewLoading(true);
        api.get(`user/${props.match.params.id}/reviews?page=${reviewsPaginate.current?.page}&profile=worker&type=received&occupation_id=${props.match.params.occupation}`,(reviewsRes) => {
            reviewsPaginate.current = {nextPage: reviewsRes.next_page, page: reviewsRes.page + 1};
            let aux = reviews.concat(reviewsRes.reviews);
            setReviews(aux);
            setReviewLoading(false);
        },(e) => {
            toast.error('Não foi possível recuperar as avaliações deste profissional 😕', {style: {textAlign:"center"}})
            setReviewLoading(false);
        });

    };

    const getUserContact = (id) => {
        setRequestContactLoading(true);
        api.get(`worker-contact/${id}`, (res) => {
            setPhoneNumber(res.worker.cellphone_number);
            setRequestContactLoading(false);
        }, (e) => {
            setRequestContactLoading(false);
            console.log(e)
        })
    }

    const requestContact = async () => {
        if (user) {
            window.gtag&& window.gtag('event', 'workerContactTouch');
            setRequestContactLoading(true);
            let sendObj = {
                application: Env.application_alias,
                occupation_id: props.match.params.occupation,
                worker_id: props.match.params.id
            };
            api.post(`worker-contact/request`, sendObj, (res) => {
                user.current.profile.requested_contact_id = res?.worker_contact_id;
                getUserContact(res?.worker_contact_id);
            }, (e) => {
                setRequestContactLoading(false);
                console.log(e)
            })
        }
    }

    useEffect(() => {
        if(props.match.params.id) {
            getData(props.match.params.id);
            getReviews();
        }
        window.scrollToElement('body');
    },[]);

    return (
        <div className='d-flex justify-content-center' style={{minHeight: '500px'}}> { loading ?
            <Loading/>
            :
            <Container className='py-5'>
                <div className='d-block d-md-none'>
                    <ServiceProviderProfile faceLink={user.current?.instagram_link} instaLink={user.current?.facebook_link} occupation={occupation.current}
                                            avatar={user.current?.profile?.profile_picture?.full || Images.user_place_holder}
                                            name={user.current?.profile?.first_name + ' ' + user.current?.profile?.last_name}
                    />
                </div>
                <div className='d-flex flex-wrap flex-column-reverse flex-md-row'>
                    <Col xs={12} md={7} lg={8} className='service-provider-main'>
                        <div className='d-none d-md-block'>
                            <ServiceProviderProfile occupation={occupation.current}
                                                    avatar={user.current?.profile?.profile_picture?.full || Images.user_place_holder}
                                                    name={user.current?.profile?.first_name + ' ' + user.current?.profile?.last_name}
                            />
                        </div>

                        <ServiceProviderMain params={props.match.params}
                                             activities={user.current?.occupations}
                                             actualOccupation={occupation.current}
                                             lastSeen={user.current?.profile?.last_seen}
                                             reviewsLoading={reviewLoading}
                                             loadMore={() => getReviews()}
                                             nextPage={reviewsPaginate.current?.nextPage}
                                             occupationName={occupation.current?.name}
                                             reviews={reviews}
                                             avatar={user.current?.profile?.profile_picture?.full || Images.user_place_holder}
                                             name={user.current?.profile?.first_name + ' ' + user.current?.profile?.last_name}
                                             address={user.current?.addresses}
                                             bio={user.current?.profile?.bio}
                        />
                    </Col>

                    <Col xs={12} md={5} lg={4} className='service-provider-extras p-3 d-flex flex-column gap-3'>
                        <ServiceProviderExtras requestContactLoading={requestContactLoading} phoneNumber={phoneNumber} requestContact={(item) => requestContact(item)} occupations={user.current?.occupations} actualOccupation={occupation.current}/>
                    </Col>
                </div>
            </Container>
            }
        </div>
    );
};

ServicePage.propTypes = {};

export default ServicePage;
