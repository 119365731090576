import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import {FaBriefcase, FaHome, FaSearch} from "react-icons/fa";
import {MdDashboard} from "react-icons/md";
import {BsFillBellFill} from "react-icons/bs";

const NavbarBottom = ({history, isLogged}) => {
    let path = window.location.pathname

    return (
        <Navbar style={{zIndex:201}} className='bg-white d-lg-none p-0 justify-content-center' fixed={"bottom"}>
            <Nav className='header col-12 col-sm-8 align-items-center'>
                <button className={`itens-menu flex-column flex-1 ${path === '/quero-contratar' ? 'active-blue' : ''}`} disabled={path === '/quero-contratar'} onClick={() => {history.push('/quero-contratar');}}>
                    <FaSearch/><span>Buscar</span>
                </button>

                <div className=
                         {`itens-menu flex-column flex-1
                            ${(path === '/' || path === '/painel/cliente') ? 'active-blue' : ''}
                         `}
                     onClick={() =>{isLogged () ? history.push('/painel') : history.push('/'); }}>

                    {isLogged() ?
                        <>
                            <MdDashboard/> <span>Painel</span>

                        </> :
                        <>
                            <FaHome/> <span>Home</span>
                        </>
                    }
                </div>

                {!isLogged() &&
                    <div className=
                         {`itens-menu flex-column flex-1
                            ${(path === '/trabalhar') ? 'active-blue' : ''}
                         `}  onClick={() => {history.push('/trabalhar'); }}>
                        <FaBriefcase/><span className='text-center'>Divulgue seu serviço</span>
                    </div>
                }

                {isLogged() &&
                    <div className=
                         {`itens-menu flex-column flex-1
                            ${(path === '/painel/cliente/notificacoes') ? 'active-blue' : ''}
                         `}  onClick={() => {history.push('/painel/cliente/notificacoes')}}>
                        <BsFillBellFill/> Notificações
                    </div>
                }
            </Nav>
        </Navbar>
    )
}

export default NavbarBottom