import React, {useCallback, useEffect, useRef, useState} from 'react';
import range from 'lodash/range';
import moment from "moment";
import {Env} from "../helpers/Env";
import {Images} from "../helpers/Images";
import {toast} from "react-hot-toast";
import {CgClose} from "react-icons/all";

export const useMergeState = initialState => {
    const [state, dispatch] = useState(initialState);

    const setState = (any) => {
        dispatch(prev => ({...prev, ...any}))
    }

    return [state, setState]
}

function countNumbers(v) {
    if (!v || !v.trim()) {
        return undefined;
    }
    let numb = v.match(/\d/g);
    if(numb) {
        return numb.join("");
    } else return ''

}
export const showNotification = () => {
    toast(
        <div>
            <div className='text-end'>
                <CgClose onClick={() => toast.dismiss()} style={{cursor:"pointer"}} size={20}/>
            </div>
            <div style={{marginBottom: 16, textAlign: 'center'}}>
                <img src={Images.logo} style={{height: 50}} alt='logo'/>
            </div>
            <div className='text-center'>
                Obrigado por tentar utilizar nossos serviços, nesta plataforma só é pertinente ao estado de {Env.state_restriction_name}
            </div>
        </div>, {
            position:"top-right",
            duration: 8000,
            style: {
                maxWidth:'400px'
            },
        }
    )
};


export const checkStateRestriction = (result) => {
    if (Env.state_restriction_uf !== 'none') {
        if (result) {
            for(let i = 0; i < result?.address_components?.length; i++) {
                for(let j = 0; j < result.address_components[i].types.length; j++) {
                    if(result.address_components[i].types[j] === 'administrative_area_level_1') {
                        if(result.address_components[i].short_name === Env.state_restriction_uf) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            }
            return false;
        }
    } else {
        return !!result;
    }
};

export const globalMaskReal = (a) => {

    if(a) {
        let aux = a;
        if(typeof a === 'string') {
            aux = a.replace(',','.');
        }
        if(isNaN(aux)) {
            aux = aux.replace(/\./g, '');
        }
        aux = Number(aux).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        return aux.toString();
    } else {
        return '---';
    }
}

export const useGeolocation = () => {
     async function getGeoFromBrowser() {
        if (navigator.geolocation) {
            let latLng = {};
            let loc = '';
            navigator.geolocation.getCurrentPosition(item => {
                latLng = {latitude: item.coords.latitude, longitude: item.coords.longitude};
                let geocoder = new window.google.maps.Geocoder;
                geocoder.geocode({'location': {lat: item.coords.latitude, lng: item.coords.longitude }}, function(results, status) {
                    loc = results[0].formatted_address;
                    return {latLng, loc};
                });
            });
        }
    };
    return {getGeoFromBrowser}
}

export const usePaginate = () => {

    const paginateObj = {
        global: "",
        paginate: {
            page: 0,
            limit: 30,
            order: {
                field: "created_at",
                type: "ASC"
            }
        }
    }

    const search = (textSearch: string) => {
        paginateObj.global = textSearch;
    };

    const addField = (field: string, value: string|number) => {
        paginateObj[field] = value;
    };

    const page = (page: number) => {
        paginateObj.paginate.page = page;
    };

    const limit = (limit: number) => {
        paginateObj.paginate.limit = limit;
    };

    const order = (field: string, type: 'ASC|DESC') => {
        paginateObj.paginate.order.field = field;
        if(type) paginateObj.paginate.order.type = type;
    };

    return {page, limit, order, addField, search, paginateObj}
}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(
        () => {
            savedCallback.current = callback;
        },
        [callback]
    );


    useEffect(
        () => {
            const handler = (...args) => savedCallback.current(...args);

            if (delay !== null) {
                const id = setInterval(handler, delay);
                return () => clearInterval(id);
            }
        },
        [delay]
    );
};

/**
 *
 * @param totalRegs
 * @param regsPerRow
 * @returns {number[]}
 */
export const useNumberOfRows = (totalRegs: number, regsPerRow: number) => {
    return range(Math.ceil(totalRegs/regsPerRow));
};

export const useWindowSize = () => {
    function getSize(): { innerHeight: number, innerWidth: number, outerHeight: number, outerWidth: number, } {
        return {
            innerHeight: window.innerHeight,
            innerWidth: window.innerWidth,
            outerHeight: window.outerHeight,
            outerWidth: window.outerWidth,
        };
    }

    let [windowSize, setWindowSize] = useState(getSize());

    function handleResize() {
        setWindowSize(getSize());
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return {
        innerHeight: windowSize.innerHeight,
        innerWidth: windowSize.innerWidth,
        outerHeight: windowSize.outerHeight,
        outerWidth: windowSize.outerWidth,
    };
}

export const useToggle = (initialState) => {
    const [status, setStatus] = useState(initialState);

    function toggle() {
        setStatus(!status);
    }

    function reset() {
        setStatus(initialState);
    }

    return {status, toggle, reset};
}

export const useForceUpdate = () => {
    const [state, setState] = useState(Object.create(null));

    const forceUpdate = () => {
        setState(Object.create(null));
    }

    const onUpdate = (callback) => {
        if(callback) callback();
    }

    useEffect(() => {
        onUpdate();
    }, [state]);

    return {onUpdate, forceUpdate};
}

export const useFieldError = (form) => {

    const getFieldError = useCallback((fieldName) => {
        const filedError = form.getFieldError(fieldName);
        return filedError ?
            <label id="petition-title-error" className="error" htmlFor="petition-title">
                {filedError.join(', ')}
            </label>
            : null
    })

    const getFieldTextError = useCallback((fieldName) => {
        const filedError = form.getFieldError(fieldName);
        return filedError ? filedError.join(', ') : null
    })

    return {getFieldError, getFieldTextError}
}

export const useSelectValue = () => {
    const toObjectValue = (label, value) => {
        return {value: value, label: label}
    }

    return {toObjectValue}
}

export const useValidationShorts = () => {
    const required = (message?: string) => {
        return {required: true, message: message || "Esse campo é obrigatório!"}
    };

    const passwordStrength  = () => {
        return {
            validator: (rule, value) => value.match(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/) !== null,
            message: <div style={{fontSize: 13, paddingTop: 10}}> - Mínimo de 8 caracteres <br/> - Deve conter números <br/> - Deve conter letras </div>
        }
    }

    const isValidDate  = () => {
        return {
            validator: (rule, value) => moment(value, 'DD/MM/YYYY').isValid() !== false,
            message: <div style={{fontSize: 13, paddingTop: 10}}> - Não é uma data valida </div>
        }
    }


    const strMin = (min, message?) => {
        return {
            type:'string',
            validator: (rule, value) => value.replace(/(<([^>]+)>)/ig,"").length > min,
            message: message
        }
    };

    const strMax = (max, message?) => {
        return {type:'string', max: max, message: message || "Esse campo é obrigatório!"}
    };

    const numMax = (max, message?) => {
        return {type:'number', transform(value) {return parseInt(value)}, max: max, message: message || "Esse campo é obrigatório!"}
    };

    const numMin = (min, message?) => {
        return {type:'number', transform(value) {return parseInt(value)}, min: min, message: message || "Esse campo é obrigatório!"}
    };

    const isEmail = (min, message?) => {
        return {type:'email', message: message || "Esse campo precisa ser um email válido!"}
    };

    const numMinWithMask = (min, message?) => {
        return {transform(value) {return countNumbers(value)}, min: min, message: message || "Esse campo é obrigatório!"}
    };

    return {required,passwordStrength,isValidDate, numMinWithMask, strMin, strMax, numMax, numMin, isEmail}
}

/**
 * Se o elemento ref está visível
 * @param ref
 * @param rootMargin
 * @returns {boolean}
 */
const useOnScreen = (ref, rootMargin = '0px') => {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.unobserve(ref.current);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return isIntersecting;
}
